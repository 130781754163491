<template>
    <div id="app">
        <v-app id="inspire" :style="{background: '#FFC324'}">

            <v-container>
                    <v-tabs
                        v-model="tab"
                        background-color="#273b81"
                        centered
                        dark
                        icons-and-text
                    >
                    <v-tabs-slider color="#ef2472"></v-tabs-slider>

                        <v-tab href="#tab-1">
                            จังหวัด
                            <v-icon>mdi-store-marker</v-icon>
                        </v-tab>

                        <v-tab href="#tab-2">
                            หมวดหมู่
                            <v-icon>mdi-folder-marker-outline</v-icon>
                        </v-tab>

                    </v-tabs>

                    <v-tabs-items v-model="tab">

                        <v-tab-item
                            value="tab-1"
                        >
                            <v-card flat elevation="5">
                            <v-card-text>
                                <!-- <template v-for="(item,index) in emer2">
                                    <div class="pt-2" :key="index">
                                        <div class="px-4">
                                            <font color="#273b81"><b>{{item.name}}</b></font>
                                        </div>
                                        <div class="px-4" v-if="item.detail != null">
                                            {{item.detail}}
                                        </div>
                                        <div>
                                            <v-btn v-if="item.tel != null" :href="'tel:'+item.tel" text>
                                                <v-icon>mdi-phone</v-icon> {{item.tel}}
                                            </v-btn>
                                            <v-btn  v-if="item.lat != null" :href="'http://maps.google.com/maps?q=loc:'+item.lat+','+item.lon" target="_blank" text>
                                                <v-icon>mdi-map-marker</v-icon> แผนที่
                                            </v-btn>
                                        </div>
                                    </div>
                                </template> -->

                                <v-expansion-panels v-model="panel" multiple focusable>
                                    <template v-for="(province,i) in Object.keys(emer2)">
                                    <v-expansion-panel
                                         v-if="province == current"
                                        :key="i"
                                    >
                                    <v-expansion-panel-header>
                                        <font class="text-h6" color="#273b81">{{province}}</font>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <template v-for="(item,index) in emer2[province]">
                                            <div :key="index">
                                                <div class="px-2 pt-2">
                                                    <font><b>{{item.name}}</b></font>
                                                </div>
                                                <div class="px-2" v-if="item.detail != null">
                                                    {{item.detail}}
                                                </div>
                                                <div>
                                                    <v-btn v-if="item.tel != null" 
                                                        :href="'tel:'+item.tel" 
                                                        color="#FFC324"
                                                        text
                                                    >
                                                        <v-icon>mdi-phone</v-icon> <font color="black"><b>{{item.tel}}</b></font>
                                                    </v-btn>
                                                    <v-btn  v-if="item.lat != null" 
                                                        :href="'http://maps.google.com/maps?q=loc:'+item.lat+','+item.lon" 
                                                        target="_blank" 
                                                        color="#ef2472"
                                                        text
                                                    >
                                                        <v-icon>mdi-map-marker</v-icon> <font color="black"><b>แผนที่</b></font>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </template>
                                    </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    </template>
                                </v-expansion-panels>

                                <v-expansion-panels class="pt-5" focusable>
                                    <v-expansion-panel
                                        v-for="(province,i) in Object.keys(emer2)"
                                        :key="i"
                                    >
                                    <v-expansion-panel-header v-if="province != current">
                                        <font class="text-h6" color="#273b81">{{province}}</font>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <template v-for="(item,index) in emer2[province]">
                                            <div :key="index">
                                                <div class="px-2 pt-2">
                                                    <font><b>{{item.name}}</b></font>
                                                </div>
                                                <div class="px-2" v-if="item.detail != null">
                                                    {{item.detail}}
                                                </div>
                                                <div>
                                                    <v-btn v-if="item.tel != null" 
                                                        :href="'tel:'+item.tel" 
                                                        color="#FFC324"
                                                        text
                                                    >
                                                        <v-icon>mdi-phone</v-icon> <font color="black">{{item.tel}}</font>
                                                    </v-btn>
                                                    <v-btn  v-if="item.lat != null" 
                                                        :href="'http://maps.google.com/maps?q=loc:'+item.lat+','+item.lon" 
                                                        target="_blank" 
                                                        color="#ef2472"
                                                        text
                                                    >
                                                        <v-icon>mdi-map-marker</v-icon> <font color="black">แผนที่</font>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </template>
                                    </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card-text>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item
                            value="tab-2"
                        >
                            <v-card flat elevation="5">
                            <v-card-text>

                                <v-expansion-panels class="pt-3" focusable>
                                    <v-expansion-panel
                                        v-for="(cat,i) in Object.keys(emer3)"
                                        :key="i"
                                    >
                                    <v-expansion-panel-header>
                                        <font class="text-h6" color="#273b81">{{cat}}</font>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <template v-for="(item,index) in emer3[cat]">
                                            <div :key="index">
                                                <div class="px-2 pt-2">
                                                    <font><b>{{item.name}}</b></font>
                                                </div>
                                                <div class="px-2" v-if="item.detail != null">
                                                    {{item.detail}}
                                                </div>
                                                <div>
                                                    <v-btn v-if="item.tel != null" 
                                                        :href="'tel:'+item.tel" 
                                                        color="#FFC324"
                                                        text
                                                    >
                                                        <v-icon>mdi-phone</v-icon> <font color="black">{{item.tel}}</font>
                                                    </v-btn>
                                                    <v-btn  v-if="item.lat != null" 
                                                        :href="'http://maps.google.com/maps?q=loc:'+item.lat+','+item.lon" 
                                                        target="_blank" 
                                                        color="#ef2472"
                                                        text
                                                    >
                                                        <v-icon>mdi-map-marker</v-icon> <font color="black">แผนที่</font>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </template>
                                    </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>

                    <v-btn
                        large
                        @click="backPage()"
                        block
                    >
                        ย้อนกลับ
                    </v-btn>
            </v-container>

        </v-app>
    </div>
</template>

<script>
    export default {
        name: 'Emergency',
        data: function() {
        return {
                emer:'',
                tab: null,
                crd: '',
                emer1:'',
                emer2:'',
                emer3:'',
                panel:[0],
                current:'',
            };
        },

        mounted () {
            window.scrollTo(0, 0)
        },

        beforeMount() {
            navigator.geolocation.getCurrentPosition(this.getCoordinate);
            this.initialize();
        },

        methods: {

            initialize:async function(){
                var app = this;
                let formData = new FormData();
                formData.append('mode', 'emergency');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        console.log("error")
                    }else{
                        app.emer1 = response.data[0]
                        app.emer2 = response.data[1]
                        app.emer3 = response.data[2]
                        // console.log(Object.keys(response.data[2]))
                        // console.log(response.data[2]['นนทบุรี'])
                        
                    }
                })
                .catch(function (error) {
                    console.log(error)
                });
            },

            getProvince:async function(){
                const url = 'https://api.nostramap.com/Service/V2/Location/Identify'
                const data = {
                    key: this.$mkey,
                    lat: this.crd.latitude,
                    lon: this.crd.longitude,
                }
                const response = await this.$jsonp(url, {data: data})
                this.current = response.results[0].AdminLevel1_L
            },

            getCoordinate(pos){
                this.crd = pos.coords;
                this.getProvince();
            },

            backPage: function(){
                history.back();
            },

        },
    }

</script>
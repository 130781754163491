<template>
    <div id="app">
        <v-app id="inspire" :style="{background: '#FFC324'}">
            
            <v-container>
                <v-card>
                    <v-toolbar
                        style="background-color:#273b81"
                        dark
                    >
                        <v-toolbar-title><v-icon>mdi-account</v-icon>&nbsp;ข้อมูลส่วนตัว</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form v-model="verifyEdit" ref="formEdit" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="12" md="12" class="pt-3">
                                    <!-- <v-text-field label="รหัสสมาชิก" v-model="itemEdit.cus5" readonly></v-text-field> -->
                                    <b>รหัสสมาชิก {{itemEdit.cus5}}</b>
                                </v-col>
                                <v-col cols="12" md="12" class="pt-3" v-if="itemEdit.cus17 != null && itemEdit.cus17 != ''">
                                    <!-- <v-text-field label="ผู้ดูแล" v-model="itemEdit.cus17" readonly></v-text-field> -->
                                    <b>ผู้ดูแล {{itemEdit.cus17}}</b>
                                </v-col>
                                <v-col cols="12" md="12" class="pt-3">
                                    <!-- <v-text-field label="โทรศัพท์" :rules="rulesBlank" v-model="itemEdit.cus7" readonly></v-text-field> -->
                                    <b>เบอร์โทรศัพท์ {{itemEdit.cus7}}</b>
                                    <v-btn :color="this.$blue" small class="ml-3 white--text" @click="showPhoneOld">แก้ไขเบอร์โทรศัพท์</v-btn>
                                </v-col>
                                <!-- <v-col cols="12" md="2">
                                    <v-text-field label="คำนำหน้า" :rules="rulesBlank" v-model="itemEdit.cus2" :readonly="readonly"></v-text-field>
                                </v-col> -->
                                <v-col cols="12" md="6" class="pt-3">
                                    <v-text-field label="ชื่อ" :rules="rulesBlank" v-model="itemEdit.cus3" :readonly="readonly"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field label="E-Mail" v-model="itemEdit.cus4" :readonly="readonly"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-menu
                                        v-model="pickerBD"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="itemEdit.cus6" label="วันเกิด" prepend-icon="mdi-calendar"
                                            readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker 
                                            v-model="birthday" 
                                            @input="closeBD" 
                                            v-show="!readonly"
                                            year-icon="mdi-calendar-blank"
                                            locale="th"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                
                                <v-col cols="12" md="6">
                                    <v-textarea
                                        v-model="itemEdit.cus8"
                                        label="ที่อยู่"
                                        :readonly="readonly"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <!-- <v-divider class="mt-5"></v-divider> -->
                    <v-card-actions>
                        <!-- <v-btn 
                            color="error"
                            @click="cancel" 
                            v-show="!readonly"
                        >
                            ยกเลิก
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="#273b81"
                            class="white--text"
                            @click="changeMode"
                        >
                            {{textEdit}}
                        </v-btn> -->
                        <v-btn
                            color="#273b81"
                            class="white--text"
                            @click="edit"
                            block
                        >
                            แก้ไขข้อมูลส่วนตัว
                        </v-btn>
                    </v-card-actions>

                    <v-btn
                        large
                        @click="backPage()"
                        block
                    >
                        ย้อนกลับ
                    </v-btn>
                </v-card>
            </v-container>

            <v-dialog v-model="dialogPhoneOld" persistent>
                <v-card>
                <v-card-title class="text">Get OTP</v-card-title>
                <v-card-text>
                    <v-form v-model="verifyPhone" ref="formPhone" lazy-validation>
                        <v-row no-gutters>
                            <v-col cols="12" md="12">
                                <v-text-field label="OTP" :rules="rulesBlank" v-model="itemNew.otp" clearable></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="dialogPhoneOld = false">ยกเลิก</v-btn>
                    <v-btn color="#273b81" class="white--text" depressed @click="showPhoneOld()">ตกลง</v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogPhoneNew" persistent>
                <v-card>
                <v-card-title class="text">เบอร์โทรศัพท์ใหม่</v-card-title>
                <v-card-text>
                    <v-form v-model="verifyPhone" ref="formPhone" lazy-validation>
                        <v-row no-gutters>
                            <v-col cols="12" md="12">
                                <v-text-field label="เบอร์โทรศัพท์" :rules="rulesBlank" v-model="itemNew.phone" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" v-if="tk != ''">
                                <v-text-field label="OTP" :rules="rulesBlank" v-model="itemNew.otp" clearable></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="dialogPhoneNew = false">ยกเลิก</v-btn>
                    <v-btn color="#273b81" class="white--text" depressed @click="managePhone()">ตกลง</v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="alert" persistent max-width="500px">
                <v-card>
                    <v-card-title class="text">{{message}}</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="alert = false">ปิด</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-app>
    </div>
</template>

<script>
    export default {
        name: 'ProfileDetail',
        data: function() {
            return {
                alert: false,
                message: '',
                color: '',
                itemNew: {},
                verifyPhone: true,
                tk: '',
                dialogPhoneOld: false,
                dialogPhoneNew: false,
                verifyEdit: true,
                rulesBlank: [
                        v => !!v || 'Required',
                    ],
                itemEdit: {},
                pickerBD: false,
                birthday: null,
                readonly: false,
                textEdit: 'แก้ไขข้อมูลส่วนตัว',
            };
        },

        mounted () {
            window.scrollTo(0, 0)
        },

        beforeMount() {
            this.getProfile();
        },

        methods: {

            closeBD (){
                this.pickerBD = false;
                this.itemEdit.cus6 = this.birthday ? this.$moment(this.birthday).add(543, 'year').format('YYYY-MM-DD') : '';
            },

            changeMode() {
                if(this.readonly){
                    this.readonly = false
                    this.textEdit = 'ตกลง'
                }else{
                    this.edit()
                }
            },

            cancel() {
                this.readonly = true
                this.textEdit = 'แก้ไขข้อมูลส่วนตัว'
                this.getProfile()
            },
            
            getProfile:async function(){
                var app = this;
                let formData = new FormData();
                formData.append('mode', 'getProfile');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.itemEdit = response.data[0]
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            edit: async function () {
                var app = this;
                if(!this.$refs.formEdit.validate()) return;
                var formData = new FormData();
                // formData.append("cus2", app.itemEdit.cus2)
                formData.append("cus3", app.itemEdit.cus3)
                formData.append("cus4", app.itemEdit.cus4)
                formData.append("cus6", app.itemEdit.cus6)
                formData.append("cus7", app.itemEdit.cus7)
                formData.append("cus8", app.itemEdit.cus8)
                formData.append("mode", 'editCus')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = response
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        app.message = response.data
                        app.color = "success"
                        app.alert = true
                    }
                    app.readonly = true
                    app.textEdit = 'แก้ไขข้อมูลส่วนตัว'
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            showPhoneOld:async function(){
                var app = this;
                app.dialogPhoneOld = true;
                var mode = 'getOTP';
                let formData = new FormData();
                formData.append('phone', app.itemEdit.cus7);
                formData.append('otp', app.itemNew.otp);
                formData.append('tk', app.tk);
                if(app.tk != '') mode = 'checkOTP';
                formData.append('old', 'Y');
                formData.append('mode', mode);
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        var result = response.data
                        if(result.startsWith('TK|')){
                            app.tk = result.substring(3);
                        } 
                        else if(result == 'success'){
                            app.dialogPhoneOld = false;
                            app.itemNew = [];
                            app.tk = '';
                            app.dialogPhoneNew = true;
                        }
                        else if(result == 'invalid'){
                            app.message = 'OTP ไม่ถูกต้อง'
                            app.alert = true
                        }
                        else {
                            app.tk = ''
                            app.itemNew.otp = ''
                            app.message = 'ไม่สามารถทำรายการได้ กรุณาลองอีกครั้ง'
                            app.alert = true
                            console.log(result)
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            managePhone:async function(){
                if(!this.$refs.formPhone.validate()) return;
                var app = this;
                var mode = 'getOTP';
                let formData = new FormData();
                formData.append('phone', app.itemNew.phone);
                formData.append('otp', app.itemNew.otp);
                formData.append('tk', app.tk);
                if(app.tk != '') mode = 'checkOTP';
                formData.append('old', 'N');
                formData.append('mode', mode);
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        var result = response.data
                        if(result.startsWith('TK|')){
                            app.tk = result.substring(3);
                        } 
                        else if(result == 'success'){
                            app.itemNew = [];
                            app.tk = '';
                            app.dialogPhoneNew = false;
                            app.message = 'แก้ไขเรียบร้อยแล้ว'
                            app.alert = true
                            app.getProfile();
                        }
                        else if(result == 'invalid'){
                            app.message = 'OTP ไม่ถูกต้อง'
                            app.alert = true
                        }
                        else {
                            app.tk = ''
                            app.itemNew.otp = ''
                            app.message = 'ไม่สามารถทำรายการได้ กรุณาลองอีกครั้ง'
                            app.alert = true
                            console.log(result)
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            backPage: function(){
                history.back();
            },

        },
    }
</script>
<template>
    <div id="app">
        <v-app id="inspire" :style="{background: '#FFC324'}">
            
            <v-container>
                <!-- <v-list elevation="5">
                    <div class="text-h6 ma-3">
                        ประเภทประกัน 
                        <v-progress-circular
                            v-show="loading"
                            indeterminate
                            color="amber"
                        ></v-progress-circular>
                    </div>
                    <v-list-item-group
                        v-model="selected"
                        active-class="pink--text"
                        multiple
                    >
                        <template v-for="(item) in items">
                        <v-list-item
                            :key="item.t1"
                            :to="'/insure/'+item.t1"
                        >
                            <v-list-item-avatar>
                                <img :src="'images/'+item.t1+'.png'">
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.t2"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        </template>
                    </v-list-item-group>
                </v-list> -->
                
                <v-card
                    elevation="5"
                    v-show="norecord"
                >
                    <v-card-title
                    class="text-h6"
                    >ไม่พบข้อมูล</v-card-title>
                </v-card>

                <v-card
                    class="mx-auto"
                    max-width="500"
                    v-show="!norecord"
                >

                    <v-toolbar
                        style="background-color:#273b81"
                        dark
                    >
                        <v-toolbar-title><v-icon>mdi-file-document-outline</v-icon>&nbsp;ประเภทประกัน</v-toolbar-title>
                    </v-toolbar>
                    <v-progress-circular
                        v-show="loading"
                        indeterminate
                        color="amber"
                    ></v-progress-circular>

                    <v-list two-line>
                        <v-list-item-group
                            multiple
                        >
                            <template v-for="(item,index) in items">
                                <v-list-item :key="item.t2" :to="getLink(item.t1)" :style="getColor(index)">
                                    <v-avatar>
                                        <!-- <v-img :src="'images/'+item.t1+'.png'" contain></v-img> -->
                                        <v-icon>mdi-{{item.t3}}</v-icon>
                                    </v-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.t2" class="mt-1"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>

                    <v-btn
                        large
                        href="/"
                        block
                    >
                        ย้อนกลับ
                    </v-btn>
                    
                </v-card>
            </v-container>

        </v-app>
    </div>
</template>

<script>

    export default {
        name: 'InsureType',
        data: function() {
            return {
                valid: true,
                alert: false,
                message: '',
                color: '',
                items: [],
                norecord: false,
                loading: true,
            };
        },

        mounted () {
            window.scrollTo(0, 0)
        },

        beforeMount() {
            this.getAllTypes();
        },

        methods: {
            
            getAllTypes:async function(){
                var app = this;
                let formData = new FormData();
                formData.append('mode', 'getAllTypes');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.loading = false
                        app.items = response.data
                        if(!app.items.length) app.norecord = true
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            getLink: function(id){
                if(id == '1'){
                    return '/carInsure/';
                } else {
                    return '/insure/'+id;
                }
            },

            getColor: function(index){
                if(index%2 == 0){
                    return { 'background-color':''};
                } else {
                    return { 'background-color':'#E7E7E7'};
                }
            },

            backPage: function(){
                history.back();
            },

        },
    }

</script>
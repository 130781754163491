<template>
  <div class="insure" >
    <div v-if="this.$router.currentRoute.name == 'CarInsure'">
      <CarInsure />
    </div>
    <div v-else-if="$route.params.id == null && !this.$router.currentRoute.path.startsWith('/car')">
      <InsureType />
    </div>
    <div v-else-if="$route.params.id != null && $route.params.id2 == null">
      <InsureList />
    </div>
    <div v-else-if="$route.params.id != null && $route.params.id2 != null">
      <InsureDetail />
    </div>
    <div v-else-if="this.$router.currentRoute.path.startsWith('/car/') && $route.params.cid == null">
      <CarList />
    </div>
    <div v-else-if="this.$router.currentRoute.name == 'CarGasList'">
      <CarList />
    </div>
    <div v-else-if="this.$router.currentRoute.path.startsWith('/car/') && $route.params.cid != null">
      <InsureList />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import InsureType from '@/components/InsureType.vue'
import InsureList from '@/components/InsureList.vue'
import InsureDetail from '@/components/InsureDetail.vue'
import CarList from '@/components/CarList.vue'
import CarInsure from '@/components/CarInsureList.vue'

export default {
  name: 'Insure',
  components: {
    InsureType,
    InsureList,
    InsureDetail,
    CarList,
    CarInsure
  }
}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Insure from '../views/Insure.vue'
import Profile from '../views/Profile.vue'
import Tax from '../views/Tax.vue'
import Place from '../views/Place.vue'
import Emer from '../views/Emer.vue'
import Loc from '../views/Location.vue'
import History from '../views/History.vue'
import Application from '../views/Application.vue'
import CarDetail from '../views/CarDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/profile',
    name: 'ProfileDetail',
    component: Profile
  },
  {
    path: '/insure/',
    name: 'InsureType',
    component: Insure
  },
  {
    path: '/insure/:id',
    name: 'InsureList',
    component: Insure
  },
  {
    path: '/insure/:id/:id2',
    name: 'InsureDetail',
    component: Insure
  },
  {
    path: '/car/',
    name: 'CarList',
    component: Insure
  },
  {
    path: '/carInsure/',
    name: 'CarInsure',
    component: Insure
  },
  {
    path: '/cargas/',
    name: 'CarGasList',
    component: Insure
  },
  {
    path: '/cardetail/:vid',
    name: 'CarDetail',
    component: CarDetail
  },
  {
    path: '/car/:cid',
    name: 'CarInsureList',
    component: Insure
  },
  {
    path: '/download/:id/:id2',
    name: 'Download',
    component: () => import('../views/Download.vue')
  },
  {
    path: '/tax',
    name: 'TaxCalculate',
    component: Tax
  },
  {
    path: '/place',
    name: 'PlaceList',
    component: Place
  },
  {
    path: '/emergency',
    name: 'Emergency',
    component: Emer
  },
  {
    path: '/location',
    name: 'Location',
    component: Loc
  },
  {
    path: '/history',
    name: 'HistoryList',
    component: History
  },
  {
    path: '/history/:hid',
    name: 'HistoryDetail',
    component: History
  },
  {
    path: '/application',
    name: 'ApplicationList',
    component: Application
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
    <div id="app">
        <v-app id="inspire" :style="{background: this.$yellow}">
            
            <v-container>
                
                <v-card
                    class="mx-auto"
                    max-width="500"
                >

                    <v-toolbar
                        :style="'background-color:'+this.$blue"
                        dark
                    >
                        <v-toolbar-title>
                            <v-icon>mdi-wrench</v-icon>&nbsp;ประวัติการใช้บริการ
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text>
                        <v-form v-model="verifyEdit" ref="formEdit" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="12" md="12" v-show="mycar">
                                    <v-select
                                        v-model="itemEdit.vid"
                                        label="รถของฉัน"
                                        :items="cars"
                                        :item-text="cars => cars.v3+' '+cars.v4+' '+cars.v5+' '+cars.v6"
                                        :item-value="cars => cars.v1"
                                        style="font-size: 1em"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <!-- <v-text-field label="บริการที่ใช้" v-model="itemEdit.name" :rules="rulesBlank" clearable></v-text-field> -->
                                    <v-combobox
                                        v-model="itemEdit.name"
                                        :items="service"
                                        label="บริการที่ใช้"
                                        :rules="rulesBlank"
                                        :menu-props="{ maxHeight: '100%' }"
                                        clearable
                                    ></v-combobox>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-menu
                                        v-model="pickerST"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="itemEdit.startdate" label="วันที่ใช้บริการ" prepend-icon="mdi-calendar"
                                            v-bind="attrs" v-on="on" :rules="rulesBlank" clearable></v-text-field>
                                        </template>
                                        <v-date-picker 
                                            v-model="startdate" 
                                            @input="closeST"
                                            year-icon="mdi-calendar-blank"
                                            locale="th"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="10">
                                    <!-- <v-combobox
                                        v-model="itemEdit.store"
                                        :items="stores"
                                        item-text="name"
                                        item-value="name"
                                        label="ร้านค้าที่ใช้บริการ"
                                        @input.native="itemEdit.store=$event.srcElement.value"
                                        @change="setVal"
                                        :rules="rulesBlank"
                                        clearable
                                    ></v-combobox> -->
                                    <v-text-field label="ร้านค้าที่ใช้บริการ" v-model="itemEdit.store" :rules="rulesBlank" clearable></v-text-field>
                                </v-col>
                                <v-col cols="2" align="center" class="pt-5">
                                    <v-btn
                                        @click="showMap"
                                        text
                                    >
                                        <v-icon>mdi-map-search</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-text-field label="เบอร์โทรศัพท์ร้านค้า" v-model="itemEdit.tel" clearable></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-textarea label="รายละเอียด" v-model="itemEdit.detail" clearable></v-textarea>
                                </v-col>
                                <v-col cols="12" md="12" class="d-flex">
                                    <v-switch
                                        v-model="itemEdit.notify"
                                        :color="this.$blue"
                                        label="ต้องการแจ้งเตือนผ่าน LINE"
                                    ></v-switch>
                                    <v-btn
                                        class="mt-3"
                                        @click="info = true"
                                        text
                                    >
                                        <v-icon>mdi-information-variant</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-menu
                                        v-model="pickerAL"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="itemEdit.alertdate" label="วันที่แจ้งเตือน" prepend-icon="mdi-calendar"
                                            v-bind="attrs" v-on="on" :rules="rules2" clearable></v-text-field>
                                        </template>
                                        <v-date-picker 
                                            v-model="alertdate" 
                                            @input="closeAL"
                                            year-icon="mdi-calendar-blank"
                                            locale="th"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="1"></v-col>
                                <v-col cols="5">
                                    <v-select :items="['07:00','12:00','18:00']"
                                    v-model="itemEdit.hour" :rules="rules2" label="เวลา" clearable></v-select>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-text-field label="ข้อความที่ต้องการแจ้งเตือน" v-model="itemEdit.message" :rules="rules2" clearable></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                        <v-col cols="12">
                        <v-btn
                            :color="this.$blue"
                            class="white--text"
                            block
                            @click="manage"
                        >
                            {{mode}}
                        </v-btn>
                        </v-col>
                        <v-col cols="12">
                        <v-btn
                            :color="this.$red"
                            class="white--text"
                            block
                            @click="confirm = true"
                            v-if="this.hid != 'new'"
                        >
                            ลบข้อมูล
                        </v-btn>

                        </v-col>
                        </v-row>
                    </v-card-actions>

                    <v-btn
                        large
                        @click="backPage()"
                        block
                    >
                        ย้อนกลับ
                    </v-btn>
                    
                </v-card>
            </v-container>

            <v-dialog v-model="confirm" persistent max-width="500px">
                <v-card>
                    <v-card-title class="text">คุณต้องการลบใช่หรือไม่</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :color="this.$red" class="white--text" @click="deleteHis">ลบข้อมูล</v-btn>
                        <v-btn :color="this.$gray" class="white--text" @click="confirm = false">ยกเลิก</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="map" persistent>
                <v-card height="80vh">
                    <div style="position: absolute;right:1px">
                        <v-btn text @click="map = false"><v-icon>mdi-close-box-outline</v-icon></v-btn>
                    </div>
                    <iframe id="maps" width="100%" height="90%" frameborder="0" src="/map.html"></iframe>
                    <v-card-actions>
                        <v-btn :color="this.$blue" class="white--text" block  @click="setVal">เลือก</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="info" max-width="300">
                <v-card>
                    <v-card-title class="text">Help</v-card-title>
                    <v-card-text>
                        ต้องเป็นเพื่อนกับ CARIGO App ก่อน <br>เพื่อให้ใช้การแจ้งเตือนได้<br>
                        <v-btn
                            href="https://lin.ee/pKS2HYV"
                            text
                            class="mt-3"
                            target="_blank"
                        ><v-icon large>mdi-account-plus</v-icon> &nbsp;&nbsp;<font color="black">เพิ่มเพื่อน</font></v-btn>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="info = false">ปิด</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="alert" persistent max-width="500px">
                <v-card>
                    <v-card-title class="text">{{message}}</v-card-title>
                    <v-card-actions>
                        <v-btn :color="this.$blue" class="white--text" block  @click="closeDialog">ปิด</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
        </v-app>
    </div>
</template>

<script>

    export default {
        name: 'CarList',
        data: function() {
                return {
                    hid: '',
                    mycar: false,
                    cars: [],
                    pickerST: false,
                    startdate: null,
                    pickerAL: false,
                    alertdate: null,
                    service: [],
                    stores: [],
                    mode: 'เพิ่มข้อมูล',
                    itemEdit: {},
                    verifyEdit: true,
                    rulesBlank: [
                            v => !!v || 'Required',
                    ],
                    rules2: [
                        v =>{
                            if(!this.itemEdit.notify) return true
                            return !!v || 'Required'
                        } 
                    ],
                    map: false,
                    confirm: false,
                    info: false,
                    alert: false,
                    message: '',
                    redirect: false,
            };
        },

        mounted () {
            window.scrollTo(0, 0);
        },

        beforeMount() {
            this.getService();
            // this.getStore();
            this.hid = this.$route.params.hid
            if(this.hid != 'new') {
                this.mode = 'แก้ไขข้อมูล'
                this.getHistory();
            } else {
                this.itemEdit.notify = false;
            }
            this.getCars();
        },

        methods: {
            
            getHistory:async function(){
                var app = this;
                let formData = new FormData();
                formData.append('hid', this.hid);
                formData.append('mode', 'getHistory');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.itemEdit = response.data[0]
                        if(app.itemEdit.notify == '0'){
                            app.itemEdit.notify = false;
                        } else {
                            app.itemEdit.notify = true;
                            if(app.itemEdit.alertdate != null){
                                app.itemEdit.hour = (app.itemEdit.alertdate).substring(11,16);
                                app.itemEdit.alertdate = app.$moment(app.itemEdit.alertdate).add(543, 'year').format('YYYY-MM-DD')
                            }
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            getCars:async function(){
                var app = this;
                let formData = new FormData();
                formData.append('mode', 'getCars');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.cars = response.data
                        app.cars.push({'v1':'','v3':'ไม่ระบุ','v4':'','v5':'','v6':''});
                        if(app.cars.length) app.mycar = true
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            closeST (){
                this.pickerST = false;
                this.itemEdit.startdate = this.startdate ? this.$moment(this.startdate).add(543, 'year').format('YYYY-MM-DD') : '';
            },

            closeAL (){
                this.pickerAL = false;
                this.itemEdit.alertdate = this.alertdate ? this.$moment(this.alertdate).add(543, 'year').format('YYYY-MM-DD') : '';
            },

            getStore: async function () {
                var app = this;
                var formData = new FormData();
                formData.append("mode", 'getStore')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    app.stores = response.data
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            getService: async function () {
                var app = this;
                var formData = new FormData();
                formData.append("mode", 'getService')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    app.service = response.data
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            manage: async function () {
                if(!this.$refs.formEdit.validate()) return;
                var app = this;
                let formData = new FormData();
                formData.append('name', this.itemEdit.name);
                formData.append('detail', this.itemEdit.detail);
                formData.append('startdate', this.itemEdit.startdate);
                // if(typeof this.itemEdit.store == 'object'){
                //     formData.append('store', this.itemEdit.store.name);
                //     formData.append('fid', this.itemEdit.store.fid);
                // } else {
                //     formData.append('store', this.itemEdit.store);
                //     formData.append('fid', this.itemEdit.fid);
                // }
                formData.append('store', this.itemEdit.store);
                formData.append('tel', this.itemEdit.tel);
                formData.append('latlon', this.itemEdit.latlon);
                formData.append('nosid', this.itemEdit.nosid);
                if(app.itemEdit.notify) {
                    var aDate = this.$moment(this.itemEdit.alertdate).add(-543, 'year').format('YYYY-MM-DD')
                    formData.append("notify", '1')
                    formData.append('message', this.itemEdit.message);
                    formData.append('alertdate', aDate + " " + this.itemEdit.hour + ":00");
                } else {
                    formData.append('message', '');
                    formData.append('alertdate', '');
                    formData.append("notify", '0')
                }
                if(this.hid != 'new'){
                    formData.append('hid', this.hid);
                }
                formData.append('vid', this.itemEdit.vid);
                formData.append('mode', 'manageHistory');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin");
                    }else{
                        app.alert = true;
                        if(response.data == 'success'){
                            app.message = app.mode+"เรียบร้อยแล้ว"
                            app.redirect = true
                        } else {
                            app.message = "ERROR Please contact admin"
                            console.log(response.data)
                        }
                        
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
                
            },

            deleteHis: async function () {
                var app = this;
                app.confirm = false;
                let formData = new FormData();
                formData.append('hid', this.hid);
                formData.append('mode', 'delHistory');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin");
                    }else{
                        app.alert = true;
                        if(response.data == 'success'){
                            app.message = "ลบข้อมูลเรียบร้อยแล้ว"
                            app.redirect = true
                        } else {
                            app.message = "ERROR Please contact admin"
                            console.log(response.data)
                        }
                        
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            closeDialog: function(){
                this.alert = false
                if(this.redirect) this.$router.push("/history");
            },

            showMap: async function(){
                this.map = true;

                // var id = 'TH'
                // var map = new this.nostra.maps.Map("map", {
                //     id: "mapTest",
                //     logo: true,
                //     scalebar: true,
                //     slider: true,
                //     level: 15,
                //     lat: 13.722944,
                //     lon: 100.530449
                // });

                // map.events.load = function () {
                //     // hideLoading();
                // };
                // map.events.click = function (e) {
                //     // showLoading();
                //     var lat = e.mapPoint.y;
                //     var lon = e.mapPoint.x;
                //     var iden = new this.nostra.services.Search.Identify({ lat: lat, lon: lon, country: id });
                //     iden.execute(function (result) {
                //         // hideLoading();

                //         if (result.results && result.results.length > 0) {
                //             console.log(result)
                //             var No = "No: " + ((result.results[0].No) ? result.results[0].No : "-");
                //             var Distance = "<br />Distance: " + ((result.results[0].Dist) ? parseFloat(result.results[0].Dist).toFixed(2) : parseFloat(result.results[0].dist).toFixed(2));
                //             var Catcode = "<br />CatCode: " + ((result.results[0].CatCode) ? result.results[0].CatCode : "-");
                //             var NostraId = "<br />Nostraid: " + ((result.results[0].NostraId) ? result.results[0].NostraId : "-");
                //             var HouseNo = "<br />HouseNo: " + ((result.results[0].HouseNo) ? result.results[0].HouseNo : "-");
                //             var Name_L = "<br />Name_L: " + ((result.results[0].Name_L) ? result.results[0].Name_L : "-");
                //             var Name_E = "<br />Name_E: " + ((result.results[0].Name_E) ? result.results[0].Name_E : "-");
                //             var Branch_L = "<br />Branch_L: " + ((result.results[0].Branch_L) ? result.results[0].Branch_L : "-");
                //             var Branch_E = "<br />Branch_E: " + ((result.results[0].Branch_E) ? result.results[0].Branch_E : "-");
                //             var AdminLevel1_L = "<br />AdminLevel1_L: " + ((result.results[0].AdminLevel1_L) ? result.results[0].AdminLevel1_L : "-");
                //             var AdminLevel1_E = "<br />AdminLevel1_E: " + ((result.results[0].AdminLevel1_E) ? result.results[0].AdminLevel1_E : "-");
                //             var AdminLevel2_L = "<br />AdminLevel2_L: " + ((result.results[0].AdminLevel2_L) ? result.results[0].AdminLevel2_L : "-");
                //             var AdminLevel2_E = "<br />AdminLevel2_E: " + ((result.results[0].AdminLevel2_E) ? result.results[0].AdminLevel2_E : "-");
                //             var AdminLevel3_L = "<br />AdminLevel3_L: " + ((result.results[0].AdminLevel3_L) ? result.results[0].AdminLevel3_L : "-");
                //             var AdminLevel3_E = "<br />AdminLevel3_E: " + ((result.results[0].AdminLevel3_E) ? result.results[0].AdminLevel3_E : "-");
                //             var AdminLevel4_L = "<br />AdminLevel4_L: " + ((result.results[0].AdminLevel4_L) ? result.results[0].AdminLevel4_L : "-");
                //             var AdminLevel4_E = "<br />AdminLevel4_E: " + ((result.results[0].AdminLevel4_E) ? result.results[0].AdminLevel4_E : "-");
                //             var Postcode = "<br />Postcode: " + ((result.results[0].PostCode) ? result.results[0].PostCode : "-");
                //             var Telephone = "<br />Telephone: " + ((result.results[0].Telephone) ? result.results[0].Telephone : "-");

                //             var strAdminCode1 = "";
                //             var strAdminCode2 = "";
                //             var strAdminCode3 = "";
                //             if (result.results[0].AdminLevel1Code != null) {
                //                 strAdminCode1 = result.results[0].AdminLevel1Code;
                //             }

                //             if (result.results[0].AdminLevel2Code != null) {
                //                 strAdminCode2 = result.results[0].AdminLevel2Code;
                //             }

                //             if (result.results[0].AdminLevel3Code != null) {
                //                 strAdminCode3 = result.results[0].AdminLevel3Code;
                //             }

                //             var adminLevel1Code = "<br />AdminLevel1Code: " + ((strAdminCode1) ? strAdminCode1 : "-");
                //             var adminLevel2Code = "<br />AdminLevel2Code: " + ((strAdminCode2) ? strAdminCode2 : "-");
                //             var adminLevel3Code = "<br />AdminLevel3Code: " + ((strAdminCode3) ? strAdminCode3 : "-");

                //             map.map.infoWindow.setTitle("Identify Result");
                //             map.map.infoWindow.setContent(No + Distance + Catcode + NostraId + HouseNo + Name_L + Name_E + Branch_L + Branch_E + AdminLevel1_L + AdminLevel1_E
                //                 + AdminLevel2_L + AdminLevel2_E + AdminLevel3_L + AdminLevel3_E + AdminLevel4_L + AdminLevel4_E + Postcode + Telephone + adminLevel1Code + adminLevel2Code + adminLevel3Code);
                //             map.map.infoWindow.show(e.screenPoint, map.map.getInfoWindowAnchor(e.screenPoint));
                //         } else {
                //             alert("Not Found : ไม่พบข้อมูล")
                //         }
                //     });
                // };
                
            },

            setVal(){
                // if(this.itemEdit.store != null){
                //     this.itemEdit.tel = this.itemEdit.store.tel
                // }
                var place = JSON.parse(document.getElementById('maps').contentWindow.document.getElementById('place').value);
                this.itemEdit.store = place.Name_L;
                this.itemEdit.tel = place.Telephone;
                this.itemEdit.latlon = place.latlon;
                this.itemEdit.nosid = place.NostraId;
                console.log(place)
                this.map = false;
            },

            backPage: function(){
                history.back();
            },
        },
    }

</script>
<template>
    <div id="app">
        <v-app id="inspire" :style="{background: '#FFC324'}">

            <v-container>
                    <v-tabs
                        v-model="tab"
                        background-color="#273b81"
                        centered
                        dark
                        icons-and-text
                    >
                    <v-tabs-slider color="#ef2472"></v-tabs-slider>

                        <v-tab href="#tab-1">
                            แจ้งเหตุ
                            <v-icon>mdi-car-emergency</v-icon>
                        </v-tab>

                        <v-tab href="#tab-2">
                            แจ้งประกัน
                            <v-icon>mdi-hospital-box-outline</v-icon>
                        </v-tab>

                        <v-tab href="#tab-3">
                            บริการรถยนต์
                            <v-icon>mdi-car-wrench</v-icon>
                        </v-tab>

                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item
                            value="tab-1"
                        >
                            <v-card flat elevation="5">
                            <v-card-text>
                                <audio id="player" src="/siren.mp3" loop></audio>
                                <v-row justify="center" class="py-4">
                                    <v-btn
                                        @click="playSound"
                                        text
                                    >
                                        <v-icon :color="this.$red" v-if="!sound">mdi-play-circle-outline</v-icon> 
                                        <v-icon :color="this.$red" v-else>mdi-pause-circle-outline</v-icon> 
                                        &nbsp; <font class="text-h6" :color="this.$red">เสียงเตือนภัย</font>
                                    </v-btn>
                                </v-row>
                                <template v-for="(item,index) in emer1">
                                    <div class="pt-2" :key="index">
                                        <div class="text-h6 px-4">
                                            <font color="#273b81"><b>{{item.name}}</b></font>
                                        </div>
                                        <div class="px-4" v-if="item.detail != null">
                                            {{item.detail}}
                                        </div>
                                        <div>
                                            <v-btn :href="'tel:'+item.tel" color="#FFC324" text>
                                                <v-icon>mdi-phone</v-icon> <font color="black"><b>{{item.tel}}</b></font>
                                            </v-btn>
                                        </div>
                                    </div>
                                </template>
                            </v-card-text>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item
                            value="tab-2"
                        >
                            <v-card flat elevation="5">
                            <v-card-text>

                                <v-expansion-panels class="pt-5" focusable>
                                    <v-expansion-panel
                                        v-for="(cat,i) in Object.keys(emer2)"
                                        :key="i"
                                    >
                                    <v-expansion-panel-header>
                                        <font class="text-h6" color="#273b81">{{cat}}</font>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <template v-for="(item,index) in emer2[cat]">
                                            <div :key="index">
                                                <div class="px-2 pt-2">
                                                    <font><b>{{item.name}}</b></font>
                                                </div>
                                                <div class="px-2" v-if="item.detail != null">
                                                    {{item.detail}}
                                                </div>
                                                <div>
                                                    <v-btn v-if="item.tel != null" 
                                                        :href="'tel:'+item.tel" 
                                                        color="#FFC324"
                                                        text
                                                    >
                                                        <v-icon>mdi-phone</v-icon> <font color="black">{{item.tel}}</font>
                                                    </v-btn>
                                                    <v-btn  v-if="item.lat != null" 
                                                        :href="'http://maps.google.com/maps?q=loc:'+item.lat+','+item.lon" 
                                                        target="_blank" 
                                                        color="#ef2472"
                                                        text
                                                    >
                                                        <v-icon>mdi-map-marker</v-icon> <font color="black">แผนที่</font>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </template>
                                    </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card-text>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item
                            value="tab-3"
                        >
                            <v-card flat elevation="5">
                            <v-card-text>

                                <v-expansion-panels class="pt-5" focusable>
                                    <v-expansion-panel
                                        v-for="(cat,i) in Object.keys(emer3)"
                                        :key="i"
                                    >
                                    <v-expansion-panel-header>
                                        <font class="text-h6" color="#273b81">{{cat}}</font>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <template v-for="(item,index) in emer3[cat]">
                                            <div :key="index">
                                                <div class="px-2 pt-2">
                                                    <font><b>{{item.name}}</b></font>
                                                </div>
                                                <div class="px-2" v-if="item.detail != null">
                                                    {{item.detail}}
                                                </div>
                                                <div>
                                                    <v-btn v-if="item.tel != null" 
                                                        :href="'tel:'+item.tel" 
                                                        color="#FFC324"
                                                        text
                                                    >
                                                        <v-icon>mdi-phone</v-icon> <font color="black">{{item.tel}}</font>
                                                    </v-btn>
                                                    <v-btn  v-if="item.lat != null" 
                                                        :href="'http://maps.google.com/maps?q=loc:'+item.lat+','+item.lon" 
                                                        target="_blank" 
                                                        color="#ef2472"
                                                        text
                                                    >
                                                        <v-icon>mdi-map-marker</v-icon> <font color="black">แผนที่</font>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </template>
                                    </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>

                    <v-btn
                        large
                        @click="backPage()"
                        block
                    >
                        ย้อนกลับ
                    </v-btn>
            </v-container>

        </v-app>
    </div>
</template>

<script>
    export default {
        name: 'Emergency',
        data: function() {
        return {
                emer:'',
                tab: null,
                crd: '',
                emer1:'',
                emer2:'',
                emer3:'',
                panel:[0],
                current:'',
                sound: false,
            };
        },

        mounted () {
            window.scrollTo(0, 0)
        },

        beforeMount() {
            navigator.geolocation.getCurrentPosition(this.getCoordinate);
            this.initialize();
        },

        methods: {

            initialize:async function(){
                var app = this;
                let formData = new FormData();
                formData.append('mode', 'emergency');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        console.log("error")
                    }else{
                        app.emer1 = response.data[0]
                        app.emer2 = response.data[3]
                        app.emer3 = response.data[4]
                        // console.log(Object.keys(response.data[2]))
                        // console.log(response.data[2]['นนทบุรี'])
                        
                    }
                })
                .catch(function (error) {
                    console.log(error)
                });
            },

            getProvince:async function(){
                const url = 'https://api.nostramap.com/Service/V2/Location/Identify'
                const data = {
                    key: this.$mkey,
                    lat: this.crd.latitude,
                    lon: this.crd.longitude,
                }
                const response = await this.$jsonp(url, {data: data})
                this.current = response.results[0].AdminLevel1_L
            },

            getCoordinate:async function(pos){
                this.crd = pos.coords;
                await this.getProvince();
            },

            playSound(){
                if(this.sound){
                    this.sound = false;
                    document.getElementById('player').pause()
                } else {
                    this.sound = true;
                    document.getElementById('player').play()
                }
            },

            backPage: function(){
                history.back();
            },

        },
    }

</script>
<template>
    <div id="app">
        <v-app id="inspire" :style="{background: '#FFC324'}">
            
            <v-container>
                <v-card>
                    <v-tabs
                        v-model="tab"
                        background-color="#273b81"
                        centered
                        dark
                        icons-and-text
                    >
                    <v-tabs-slider color="#ef2472"></v-tabs-slider>

                        <v-tab href="#tab-1">
                            ค้นหาสถานที่
                            <v-icon>mdi-map-marker-radius</v-icon>
                        </v-tab>

                        <v-tab href="#tab-2" @click="getFavorite">
                            น่าสนใจ
                            <v-icon>mdi-star</v-icon>
                        </v-tab>

                        <v-tab href="#tab-3" @click="getFavorite">
                            ร้านโปรด
                            <v-icon>mdi-store-marker</v-icon>
                        </v-tab>

                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item
                            value="tab-1"
                            style="background-color: #FFC324;"
                        >
                            <v-card flat elevation="5">
                                <v-card-text>
                                    <v-form ref="form" lazy-validation>
                                        <v-row no-gutters>
                                            
                                            <v-col cols="12" md="12">
                                                <v-select :items="items" item-text="catname" item-value="localCatCode"
                                                v-model="code" label="ประเภทของสถานที่" :menu-props="{ maxHeight: '100%' }"></v-select>
                                            </v-col>

                                            <v-col cols="12" md="12">
                                                <v-text-field label="คำค้นหา" v-model="keyword" clearable></v-text-field>
                                            </v-col>

                                            <v-col cols="12" md="12">
                                                <v-select :items="['10','20','50']"
                                                v-model="distince" label="ค้นหาภายในระยะทาง (กม.)"></v-select>
                                            </v-col>

                                            <v-col cols="12" md="12">
                                                <v-select :items="['5','10','20']"
                                                v-model="rows" label="แสดงผลการค้นหา (จุด)"></v-select>
                                            </v-col>

                                            <v-col cols="12" md="12">
                                                <v-btn
                                                    color="#273b81"
                                                    class="white--text"
                                                    block
                                                    @click="showPlace"
                                                    :loading="loading"
                                                >
                                                    ค้นหาเลย
                                                </v-btn>
                                            </v-col>
                                            
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            
                            </v-card>

                            <v-card class="mt-5" elevation="5">
                            <div class="px-5 py-3"><font color="#273b81"><b><u>ตำแหน่งปัจจุบันของฉัน</u></b></font></div>
                            <iframe 
                                frameborder="0" 
                                :src="'https://maps.google.com/maps?output=embed&z=10&q=loc:'+this.crd.latitude+','+this.crd.longitude" 
                                width="100%"
                                height="200"
                            />
                            </v-card>
                            
                            <v-carousel 
                                cycle
                                height="430"
                                class="pt-5"
                                v-show="show"
                            >
                                <template v-for="(item,index) in places">
                                    <v-carousel-item :key="index">
                                        <v-card
                                            elevation="5"
                                            height="100%"
                                        >
                                            <div class="px-8 pt-6">
                                                <font color="#273b81"><b>{{item.Name_L}}</b></font>
                                            </div>
                                            <div class="px-3 pt-2">
                                                <iframe 
                                                    frameborder="0" 
                                                    :src="'https://maps.google.com/maps?output=embed&q=loc:'+item.LatLon" 
                                                    width="100%"
                                                />
                                            </div>
                                            <div class="px-8 pt-2">
                                                <b>ที่อยู่ : </b>{{item.AdminLevel4_L}} {{item.AdminLevel3_L}} 
                                                {{item.AdminLevel2_L}} {{item.AdminLevel1_L}}
                                            </div>
                                            <div class="px-8 pt-2">
                                                <b>ระยะทาง : </b>{{item.dist.toFixed(2)}} km.
                                            </div>
                                            <div class="px-3 pt-2">
                                                <v-row>
                                                <v-col cols="6">
                                                <v-btn v-if="item.Telephone != ''" :href="'tel:'+item.Telephone" text>
                                                    <v-icon>mdi-phone</v-icon> {{item.Telephone}}
                                                </v-btn>
                                                <!-- <v-btn :href="'http://maps.google.com/maps?q=loc:'+item.LatLon" target="_blank" text>
                                                    <v-icon>mdi-map-marker</v-icon> แผนที่
                                                </v-btn> -->
                                                </v-col>
                                                <v-spacer></v-spacer>
                                                <v-col cols="auto" class="ml-n3">
                                                    <v-icon
                                                        @click="favorite(index,'F')"
                                                        v-if="item.favorite != 1"
                                                    >
                                                        mdi-star-outline
                                                    </v-icon>

                                                    <v-icon
                                                        v-else
                                                        @click="favorite(index,'F')"
                                                        color="yellow darken-3"
                                                    >
                                                        mdi-star
                                                    </v-icon>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-icon
                                                        @click="favorite(index,'S')"
                                                        v-if="item.store != 1"
                                                    >
                                                        mdi-store-plus
                                                    </v-icon>

                                                    <v-icon
                                                        v-else
                                                        @click="favorite(index,'S')"
                                                        color="yellow darken-3"
                                                    >
                                                        mdi-store-remove
                                                    </v-icon>
                                                </v-col>
                                                <v-col cols="auto" class="ml-n3">
                                                    <v-icon
                                                        @click="info = true"
                                                    >
                                                        mdi-information-variant
                                                    </v-icon>
                                                </v-col>
                                                </v-row>
                                            </div>
                                            
                                        </v-card>
                                    </v-carousel-item>
                                </template>
                            </v-carousel>

                            <v-card
                                elevation="5"
                                v-show="norecord"
                                class="mt-5"
                            >
                                <v-card-title
                                class="text-h6"
                                >ไม่พบข้อมูล</v-card-title>
                            </v-card>
                        
                        </v-tab-item>
                        
                        <v-tab-item
                            value="tab-2"
                            style="background-color: #FFC324"
                        >
                        <v-progress-circular
                            v-show="favload"
                            indeterminate
                            color="#ef2472"
                        ></v-progress-circular>
                        <template v-for="(item,index) in fav">
                            <v-row :key="index"><v-col>
                            <v-card
                                elevation="5"
                            >
                                <div class="px-8 pt-6">
                                    <font color="#273b81"><b>{{item.name}}</b></font>
                                </div>
                                <div class="px-3 pt-2">
                                    <iframe 
                                        frameborder="0" 
                                        :src="'https://maps.google.com/maps?output=embed&q=loc:'+item.latlon" 
                                        width="100%"
                                        height="100px"
                                    />
                                </div>
                                <div class="px-8 pt-2">
                                    <b>ที่อยู่ : </b>{{item.address}}
                                </div>
                                <div class="px-3 py-4">
                                    <v-row>
                                    <v-col cols="6">
                                    <v-btn v-if="item.tel != ''" :href="'tel:'+item.tel" text>
                                        <v-icon>mdi-phone</v-icon> {{item.tel}}
                                    </v-btn>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="auto">
                                        <v-icon
                                            @click="favRemove(index)"
                                        >
                                            mdi-trash-can-outline
                                        </v-icon>
                                    </v-col>
                                    </v-row>
                                </div>
                                
                            </v-card>
                            </v-col>
                            </v-row>
                        </template>

                        <v-card
                            elevation="5"
                            v-show="favNorecord"
                        >
                            <v-card-title
                            class="text-h6"
                            >ไม่พบข้อมูล</v-card-title>
                        </v-card>

                        </v-tab-item>

                        <v-tab-item
                            value="tab-3"
                            style="background-color: #FFC324"
                        >
                        <v-progress-circular
                            v-show="storeload"
                            indeterminate
                            color="#ef2472"
                        ></v-progress-circular>
                        <template v-for="(item,index) in store">
                            <v-row :key="index"><v-col>
                            <v-card
                                elevation="5"
                            >
                                <div class="px-8 pt-6">
                                    <font color="#273b81"><b>{{item.name}}</b></font>
                                </div>
                                <div class="px-3 pt-2">
                                    <iframe 
                                        frameborder="0" 
                                        :src="'https://maps.google.com/maps?output=embed&q=loc:'+item.latlon" 
                                        width="100%"
                                        height="100px"
                                    />
                                </div>
                                <div class="px-8 pt-2">
                                    <b>ที่อยู่ : </b>{{item.address}}
                                </div>
                                <div class="px-3 py-4">
                                    <v-row>
                                    <v-col cols="6">
                                    <v-btn v-if="item.tel != ''" :href="'tel:'+item.tel" text>
                                        <v-icon>mdi-phone</v-icon> {{item.tel}}
                                    </v-btn>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="auto">
                                        <v-icon
                                            @click="changeName(item)"
                                            class="mr-2"
                                        >
                                            mdi-square-edit-outline
                                        </v-icon>
                                        <v-icon
                                            @click="storeRemove(index)"
                                        >
                                            mdi-trash-can-outline
                                        </v-icon>
                                    </v-col>
                                    </v-row>
                                </div>
                                
                            </v-card>
                            </v-col>
                            </v-row>
                        </template>

                        <v-card
                            elevation="5"
                            v-show="storeNorecord"
                        >
                            <v-card-title
                            class="text-h6"
                            >ไม่พบข้อมูล</v-card-title>
                        </v-card>

                        </v-tab-item>
                    </v-tabs-items>

                    <v-btn
                        large
                        @click="backPage()"
                        block
                    >
                        ย้อนกลับ
                    </v-btn>
                </v-card>
            <div id="myLoc"></div>
            </v-container>

            <v-dialog v-model="info" max-width="300" >
                <v-card>
                    <v-card-title class="text">Help</v-card-title>
                    <v-card-text>
                        <v-icon>mdi-star-outline</v-icon> - ตั้งเป็น สถานที่น่าสนใจ<br>
                        <v-icon>mdi-store-plus</v-icon> - ตั้งเป็น ร้านโปรด<br>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="info = false">ปิด</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogChangeName" max-width="300" >
                <v-card>
                    <v-card-title class="text">แก้ไขชื่อร้าน</v-card-title>
                    <v-card-text>
                        <v-form v-model="verifyStore" ref="formStore" lazy-validation>
                            <v-text-field label="ชื่อร้าน" v-model="storeEdit.name" :rules="rulesBlank" clearable></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="dialogChangeName = false">ปิด</v-btn>
                        <v-btn color="blue darken-1" text @click="manageStore">แก้ไข</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-snackbar v-model="alert" top right :color="color">
                {{ message }}
                <v-btn text small fab @click="alert = false"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
            </v-snackbar>

        </v-app>
    </div>
</template>

<script>

    export default {
        name: 'PlaceList',
        data: function() {
            return {
                tab: null,
                crd: '',
                code: '',
                items: [],
                places: [],
                keyword: '',
                rows: '5',
                distince: '10',
                loading: false,
                show: false,
                active: false,
                fav: [],
                favload: false,
                store: [],
                storeload: false,
                norecord: false,
                favNorecord: false,
                storeNorecord: false,
                admin: this.$admin,
                info: false,
                alert: false,
                message: '',
                color: '',
                rulesBlank: [
                    v => !!v || 'Required',
                ],
                dialogChangeName: false,
                storeEdit: {},
                verifyStore: true,
            };
        },

        mounted () {
            window.scrollTo(0, 0)
        },

        beforeMount() {
            navigator.geolocation.getCurrentPosition(this.getCoordinate);
            this.getCatagories();
        },

        methods: {
            
            getCoordinate(pos){
                this.crd = pos.coords;
            },

            getCatagories: async function () {
                var app = this;
                let formData = new FormData();
                formData.append('mode', 'catagories');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.items = response.data
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            tempRes(){
                const response = {
    "errorMessage": null,
    "results": [
        {
            "No": "1",
            "NostraId": "L20000464643",
            "Name_E": "Pt Lpg Service Station Bang Yai",
            "Name_L": "สถานีบริการแก๊สแอลพีจีพีที บางใหญ่",
            "Branch_E": "Bang Yai",
            "Branch_L": "บางใหญ่",
            "AdminLevel1_E": "Nonthaburi",
            "AdminLevel1_L": "นนทบุรี",
            "AdminLevel2_E": "Bang Yai",
            "AdminLevel2_L": "บางใหญ่",
            "AdminLevel3_E": "Sao Thong Hin",
            "AdminLevel3_L": "เสาธงหิน",
            "AdminLevel4_E": "Kaeo In Rd.",
            "AdminLevel4_L": "ถนนแก้วอินทร์",
            "AdminLevel1Code": "12",
            "AdminLevel2Code": "03",
            "AdminLevel3Code": "04",
            "Catcode": "FUEL-LPG",
            "LocalCatCode": "24029",
            "HouseNo": "",
            "Telephone": "33245345345",
            "PostCode": "11140",
            "dist": 0.5325159364354421,
            "LatLon": "13.86876989,100.40951447",
            "LatLon_Route1": "13.86857682,100.41005706",
            "LatLon_Route2": "13.86857682,100.41005706",
            "LatLon_Route3": "13.86857682,100.41005706",
            "LatLon_Route4": "13.86857682,100.41005706",
            "extra_content": "N",
            "Area_Size": "0.0"
        },
        {
            "No": "2",
            "NostraId": "L20000828886",
            "Name_E": "Siam Gas Lpg Service Station",
            "Name_L": "สถานีบริการแก๊สแอลพีจีสยามแก๊ส",
            "Branch_E": "",
            "Branch_L": "",
            "AdminLevel1_E": "Nonthaburi",
            "AdminLevel1_L": "นนทบุรี",
            "AdminLevel2_E": "Bang Yai",
            "AdminLevel2_L": "บางใหญ่",
            "AdminLevel3_E": "Sao Thong Hin",
            "AdminLevel3_L": "เสาธงหิน",
            "AdminLevel4_E": "Kanchanaphisek Frontage Rd.",
            "AdminLevel4_L": "ทางคู่ขนานถนนกาญจนาภิเษก",
            "AdminLevel1Code": "12",
            "AdminLevel2Code": "03",
            "AdminLevel3Code": "04",
            "Catcode": "FUEL-LPG",
            "LocalCatCode": "24029",
            "HouseNo": "",
            "Telephone": "",
            "PostCode": "11140",
            "dist": 0.6066232568530819,
            "LatLon": "13.86783365,100.41101281",
            "LatLon_Route1": "13.86794811,100.41084500",
            "LatLon_Route2": "13.86794811,100.41084500",
            "LatLon_Route3": "13.86794811,100.41084500",
            "LatLon_Route4": "13.86794811,100.41084500",
            "extra_content": "N",
            "Area_Size": "0.0"
        },
        {
            "No": "3",
            "NostraId": "L20001428169",
            "Name_E": "Siam Gas Lpg Service Station (Chari)",
            "Name_L": "สถานีบริการแก๊สแอลพีจีสยามแก๊ส (ชารี)",
            "Branch_E": "",
            "Branch_L": "",
            "AdminLevel1_E": "Nonthaburi",
            "AdminLevel1_L": "นนทบุรี",
            "AdminLevel2_E": "Bang Yai",
            "AdminLevel2_L": "บางใหญ่",
            "AdminLevel3_E": "Sao Thong Hin",
            "AdminLevel3_L": "เสาธงหิน",
            "AdminLevel4_E": "Rattanathibet Frontage Rd.",
            "AdminLevel4_L": "ทางคู่ขนานถนนรัตนาธิเบศร์",
            "AdminLevel1Code": "12",
            "AdminLevel2Code": "03",
            "AdminLevel3Code": "04",
            "Catcode": "FUEL-LPG",
            "LocalCatCode": "24029",
            "HouseNo": "",
            "Telephone": "",
            "PostCode": "11140",
            "dist": 1.5420914062128717,
            "LatLon": "13.87485196,100.41646086",
            "LatLon_Route1": "13.87473706,100.41636951",
            "LatLon_Route2": "13.87473706,100.41636951",
            "LatLon_Route3": "13.87473706,100.41636951",
            "LatLon_Route4": "13.87473706,100.41636951",
            "extra_content": "N",
            "Area_Size": "0.0"
        },
        {
            "No": "4",
            "NostraId": "L20005913497",
            "Name_E": "Pt Lpg Service Station Bang Bua Thong 8 (Rattana Thibet Rd.)",
            "Name_L": "สถานีบริการแก๊สแอลพีจีพีที บางบัวทอง 8 (ถ.รัตนาธิเบศ)",
            "Branch_E": "Bang Bua Thong 8 (Rattana Thibet Rd.)",
            "Branch_L": "บางบัวทอง 8 (ถ.รัตนาธิเบศ)",
            "AdminLevel1_E": "Nonthaburi",
            "AdminLevel1_L": "นนทบุรี",
            "AdminLevel2_E": "Bang Bua Thong",
            "AdminLevel2_L": "บางบัวทอง",
            "AdminLevel3_E": "Bang Rak Phatthana",
            "AdminLevel3_L": "บางรักพัฒนา",
            "AdminLevel4_E": "Rattanathibet Frontage Rd.",
            "AdminLevel4_L": "ทางคู่ขนานถนนรัตนาธิเบศร์",
            "AdminLevel1Code": "12",
            "AdminLevel2Code": "04",
            "AdminLevel3Code": "08",
            "Catcode": "FUEL-LPG",
            "LocalCatCode": "24029",
            "HouseNo": "",
            "Telephone": "",
            "PostCode": "11110",
            "dist": 2.588947028785085,
            "LatLon": "13.87581848,100.42743232",
            "LatLon_Route1": "13.87554865,100.42740691",
            "LatLon_Route2": "13.87554865,100.42740691",
            "LatLon_Route3": "13.87554865,100.42740691",
            "LatLon_Route4": "13.87554865,100.42740691",
            "extra_content": "N",
            "Area_Size": "0.0"
        },
        {
            "No": "5",
            "NostraId": "L20000178495",
            "Name_E": "Unique Gas Lpg Service Station (Green Auto Gas)",
            "Name_L": "สถานีบริการแก๊สแอลพีจียูนิคแก๊ส (กรีนออโต้แก๊ส)",
            "Branch_E": "",
            "Branch_L": "",
            "AdminLevel1_E": "Nonthaburi",
            "AdminLevel1_L": "นนทบุรี",
            "AdminLevel2_E": "Bang Bua Thong",
            "AdminLevel2_L": "บางบัวทอง",
            "AdminLevel3_E": "Bang Rak Phatthana",
            "AdminLevel3_L": "บางรักพัฒนา",
            "AdminLevel4_E": "Rattanathibet Frontage Rd.",
            "AdminLevel4_L": "ทางคู่ขนานถนนรัตนาธิเบศร์",
            "AdminLevel1Code": "12",
            "AdminLevel2Code": "04",
            "AdminLevel3Code": "08",
            "Catcode": "FUEL-LPG",
            "LocalCatCode": "24029",
            "HouseNo": "",
            "Telephone": "",
            "PostCode": "11110",
            "dist": 2.684294736431906,
            "LatLon": "13.87582478,100.42841138",
            "LatLon_Route1": "13.87565478,100.42839757",
            "LatLon_Route2": "13.87565478,100.42839757",
            "LatLon_Route3": "13.87565478,100.42839757",
            "LatLon_Route4": "13.87565478,100.42839757",
            "extra_content": "N",
            "Area_Size": "0.0"
        },
        {
            "No": "6",
            "NostraId": "L20000621145",
            "Name_E": "Lpg Service Station (Sap Bo Ngoen Co., Ltd.)",
            "Name_L": "สถานีบริการแก๊สแอลพีจี (บ.ทรัพย์บ่อเงิน จำกัด)",
            "Branch_E": "",
            "Branch_L": "",
            "AdminLevel1_E": "Nonthaburi",
            "AdminLevel1_L": "นนทบุรี",
            "AdminLevel2_E": "Bang Bua Thong",
            "AdminLevel2_L": "บางบัวทอง",
            "AdminLevel3_E": "Bang Rak Yai",
            "AdminLevel3_L": "บางรักใหญ่",
            "AdminLevel4_E": "Bang Kruai-Sai Noi Rd.",
            "AdminLevel4_L": "ถนนบางกรวย-ไทรน้อย",
            "AdminLevel1Code": "12",
            "AdminLevel2Code": "04",
            "AdminLevel3Code": "03",
            "Catcode": "FUEL-LPG",
            "LocalCatCode": "24029",
            "HouseNo": "",
            "Telephone": "",
            "PostCode": "11110",
            "dist": 3.298185684497055,
            "LatLon": "13.87838709,100.43345277",
            "LatLon_Route1": "13.87828649,100.43374478",
            "LatLon_Route2": "13.87828649,100.43374478",
            "LatLon_Route3": "13.87828649,100.43374478",
            "LatLon_Route4": "13.87828649,100.43374478",
            "extra_content": "N",
            "Area_Size": "0.0"
        },
        {
            "No": "7",
            "NostraId": "L20005913557",
            "Name_E": "Pt Lpg Service Station Bang Bua Thong 4",
            "Name_L": "สถานีบริการแก๊สแอลพีจีพีที บางบัวทอง 4",
            "Branch_E": "Bang Bua Thong 4",
            "Branch_L": "บางบัวทอง 4",
            "AdminLevel1_E": "Nonthaburi",
            "AdminLevel1_L": "นนทบุรี",
            "AdminLevel2_E": "Bang Bua Thong",
            "AdminLevel2_L": "บางบัวทอง",
            "AdminLevel3_E": "Bang Rak Phatthana",
            "AdminLevel3_L": "บางรักพัฒนา",
            "AdminLevel4_E": "Bang Kruai-Sai Noi Rd.",
            "AdminLevel4_L": "ถนนบางกรวย-ไทรน้อย",
            "AdminLevel1Code": "12",
            "AdminLevel2Code": "04",
            "AdminLevel3Code": "08",
            "Catcode": "FUEL-LPG",
            "LocalCatCode": "24029",
            "HouseNo": "",
            "Telephone": "",
            "PostCode": "11110",
            "dist": 3.4434158685924503,
            "LatLon": "13.88101704,100.43353461",
            "LatLon_Route1": "13.88073321,100.43380950",
            "LatLon_Route2": "13.88073321,100.43380950",
            "LatLon_Route3": "13.88073321,100.43380950",
            "LatLon_Route4": "13.88073321,100.43380950",
            "extra_content": "N",
            "Area_Size": "0.0"
        },
        {
            "No": "8",
            "NostraId": "L20000830614",
            "Name_E": "Lpg Service Station",
            "Name_L": "สถานีบริการแก๊สแอลพีจี",
            "Branch_E": "",
            "Branch_L": "",
            "AdminLevel1_E": "Nonthaburi",
            "AdminLevel1_L": "นนทบุรี",
            "AdminLevel2_E": "Bang Yai",
            "AdminLevel2_L": "บางใหญ่",
            "AdminLevel3_E": "Bang Muang",
            "AdminLevel3_L": "บางม่วง",
            "AdminLevel4_E": "Soi Muban Kanchanalak 1",
            "AdminLevel4_L": "ซอยหมู่บ้านกาญจนาลักษณ์ 1",
            "AdminLevel1Code": "12",
            "AdminLevel2Code": "03",
            "AdminLevel3Code": "01",
            "Catcode": "FUEL-LPG",
            "LocalCatCode": "24029",
            "HouseNo": "",
            "Telephone": "",
            "PostCode": "11140",
            "dist": 3.5198235526893673,
            "LatLon": "13.83452371,100.41290793",
            "LatLon_Route1": "13.83411540,100.41318997",
            "LatLon_Route2": "13.83411540,100.41318997",
            "LatLon_Route3": "13.83411540,100.41318997",
            "LatLon_Route4": "13.83411540,100.41318997",
            "extra_content": "N",
            "Area_Size": "0.0"
        },
        {
            "No": "9",
            "NostraId": "L20005913329",
            "Name_E": "Pt Lpg Service Station Bang Yai 2",
            "Name_L": "สถานีบริการแก๊สแอลพีจีพีที บางใหญ่ 2",
            "Branch_E": "Bang Yai 2",
            "Branch_L": "บางใหญ่ 2",
            "AdminLevel1_E": "Nonthaburi",
            "AdminLevel1_L": "นนทบุรี",
            "AdminLevel2_E": "Bang Yai",
            "AdminLevel2_L": "บางใหญ่",
            "AdminLevel3_E": "Bang Len",
            "AdminLevel3_L": "บางเลน",
            "AdminLevel4_E": "Bang Kruai-Sai Noi Rd.",
            "AdminLevel4_L": "ถนนบางกรวย-ไทรน้อย",
            "AdminLevel1Code": "12",
            "AdminLevel2Code": "03",
            "AdminLevel3Code": "03",
            "Catcode": "FUEL-LPG",
            "LocalCatCode": "24029",
            "HouseNo": "",
            "Telephone": "",
            "PostCode": "11140",
            "dist": 3.544042558069846,
            "LatLon": "13.86210927,100.43860624",
            "LatLon_Route1": "13.86187188,100.43888935",
            "LatLon_Route2": "13.86187188,100.43888935",
            "LatLon_Route3": "13.86187188,100.43888935",
            "LatLon_Route4": "13.86187188,100.43888935",
            "extra_content": "N",
            "Area_Size": "0.0"
        },
        {
            "No": "10",
            "NostraId": "L20000375328",
            "Name_E": "World Gas Lpg Service Station",
            "Name_L": "สถานีบริการแก๊สแอลพีจีเวิลด์แก๊ส",
            "Branch_E": "",
            "Branch_L": "",
            "AdminLevel1_E": "Nonthaburi",
            "AdminLevel1_L": "นนทบุรี",
            "AdminLevel2_E": "Bang Bua Thong",
            "AdminLevel2_L": "บางบัวทอง",
            "AdminLevel3_E": "Bang Rak Phatthana",
            "AdminLevel3_L": "บางรักพัฒนา",
            "AdminLevel4_E": "Bang Kruai-Sai Noi Rd.",
            "AdminLevel4_L": "ถนนบางกรวย-ไทรน้อย",
            "AdminLevel1Code": "12",
            "AdminLevel2Code": "04",
            "AdminLevel3Code": "08",
            "Catcode": "FUEL-LPG",
            "LocalCatCode": "24029",
            "HouseNo": "",
            "Telephone": "",
            "PostCode": "11110",
            "dist": 3.7751443527770285,
            "LatLon": "13.88740652,100.4326346",
            "LatLon_Route1": "13.88752351,100.43249443",
            "LatLon_Route2": "13.88752351,100.43249443",
            "LatLon_Route3": "13.88752351,100.43249443",
            "LatLon_Route4": "13.88752351,100.43249443",
            "extra_content": "N",
            "Area_Size": "0.0"
        }
    ]
}
                return response
            },

            showPlace:async function(){
                // if(!this.$refs.form.validate()) return;
                if(this.code == '' && this.keyword == ''){
                    this.color = 'error';
                    this.alert = true;
                    this.message = 'กรุณาใส่ข้อมูลอย่างน้อย 1 ค่า';
                    return;
                }
                var app = this;
                app.loading = true; 
                app.show = false;
                app.norecord = false;
                const url = 'https://api.nostramap.com/Service/V2/Location/Search'
                const data = {
                    key: this.$mkey,
                    localCatCode: app.code,
                    keyword: app.keyword,
                    lat: app.crd.latitude,
                    lon: app.crd.longitude,
                    radius: app.distince,
                    rowsPerPage: app.rows,
                }
                const response = await this.$jsonp(url, {data: data}).catch(function (error) {
                    console.log(error);
                    app.norecord = true
                    app.loading = false;
                    document.getElementById('myLoc').scrollIntoView({behavior: "smooth"});
                });
                // console.log(data)
                // const response = app.tempRes();

                if(response.errorMessage != null){
                    console.log(response.errorMessage)
                    app.norecord = true
                }else{
                    let formData = new FormData();
                    formData.append('obj', JSON.stringify(response.results));
                    formData.append('mode', 'checkPlace');
                    await this.$axios.post(this.$hostUrl+'get/', formData)
                    .then(function (response) {
                        if(!response.data){
                            alert("ERROR Please contact admin")
                            app.norecord = true
                        }else{
                            // console.log(response.data)
                            app.show = true;
                            app.places = response.data;
                            if(!app.places.length) app.norecord = true
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
                app.loading = false;
                document.getElementById('myLoc').scrollIntoView({behavior: "smooth"});
            },

            getFavorite: async function () {
                var app = this;
                app.favload = true;
                app.favNorecord = false;
                app.storeNorecord = false;
                app.fav = [];
                let formData = new FormData();
                formData.append('mode', 'getFavorite');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                        app.favNorecord = true
                        app.storeNorecord = true
                    }else{
                        app.fav = response.data[0]
                        app.store = response.data[1]
                        // console.log(app.fav)
                        if(!app.fav.length) app.favNorecord = true
                        if(!app.store.length) app.storeNorecord = true
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
                app.favload = false;
            },

            favorite:async function(i,type){
                var app = this;
                if(type == 'F'){
                    if(app.places[i].favorite == 1) {
                    app.places[i].favorite = false;
                    } else {
                        app.places[i].favorite = true;
                    }
                } else {
                    if(app.places[i].store == 1) {
                        app.places[i].store = false;
                    } else {
                        app.places[i].store = true;
                    }
                }
                let formData = new FormData();
                formData.append('obj', JSON.stringify(app.places[i]));
                formData.append('type', type);
                formData.append('mode', 'favorite');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        console.log(response.data)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            favRemove:async function(i){
                var app = this;
                app.fav[i].favorite = false;
                let formData = new FormData();
                formData.append('obj', JSON.stringify(app.fav[i]));
                formData.append('type', 'F');
                formData.append('mode', 'delFav');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        // console.log(response.data)
                        app.getFavorite()
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            storeRemove:async function(i){
                var app = this;
                app.store[i].store = false;
                let formData = new FormData();
                formData.append('obj', JSON.stringify(app.store[i]));
                formData.append('type', 'S');
                formData.append('mode', 'delFav');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        // console.log(response.data)
                        app.getFavorite()
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            changeName:async function(item){
                var app = this;
                app.dialogChangeName = true;
                app.storeEdit = Object.assign({}, item);
            },

            manageStore: async function () {
                var app = this;
                if(!this.$refs.formStore.validate()) return;
                var formData = new FormData();
                if(app.storeEdit.name) formData.append("name", app.storeEdit.name)
                if(app.storeEdit.fid) formData.append("fid", app.storeEdit.fid)
                formData.append("mode", 'manageStore')
                console.log(app.storeEdit.name)
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = response
                        app.alert = true
                    }else{
                        app.dialogChangeName = false
                        app.alert = true;
                        app.color = "success"
                        app.message = response.data;
                        app.getFavorite();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    app.dialogChangeName = false
                });
            },

            backPage: function(){
                history.back();
            },

        },
    }

</script>
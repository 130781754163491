<template>
    <div id="app">
        <v-app id="inspire" :style="{background: '#FFC324'}">
            
            <v-container>
                
                <v-card
                    class="mx-auto"
                    max-width="500"
                >

                    <v-toolbar
                        style="background-color:#273b81"
                        dark
                    >
                        <v-toolbar-title>
                            <v-icon>mdi-car-side</v-icon>&nbsp;ประกันรถของฉัน
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-progress-circular
                        v-show="loading"
                        indeterminate
                        color="amber"
                    ></v-progress-circular>

                    <v-card
                        elevation="5"
                        v-show="norecord"
                    >
                        <v-card-title
                        class="text-h6"
                        >ไม่พบข้อมูล</v-card-title>
                    </v-card>

                    <v-list two-line v-show="!norecord">
                        <template v-for="(item,index) in items">
                            <v-list-item :key="index" :style="getColor(index)" :href="'/car/'+item.v1">
                                <v-avatar tile>
                                    <v-img v-if="item.v7" :src="'../images/cars/'+item.v7" contain></v-img>
                                    <v-img v-else :src="'../images/car.jpg'" contain></v-img>
                                </v-avatar>
                                &nbsp;
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span v-if="role=='agent' && item.v14"><font color="#273b81">{{item.v14}}</font><br></span>
                                        <span v-if="role=='agent' && item.v15"><font color="#273b81">{{item.v15}}</font><br></span>
                                        <span><font color="#273b81">{{item.v3}} {{item.v4}}</font><br></span>
                                        <span>{{item.v5}} {{item.v6}}<br></span>
                                    </v-list-item-title>
                                </v-list-item-content>
                                <!-- <v-btn icon @click="showEditCar(item)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                                <v-btn icon @click="deleteCarDialog(item)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn> -->
                                <!-- <v-btn icon @click="goto('/cardetail/'+item.v1)">
                                    <v-icon>mdi-car-info</v-icon>
                                </v-btn>
                                <v-btn icon :href="'/car/'+item.v1">
                                    <v-icon>mdi-shield-car</v-icon>
                                </v-btn> -->
                            </v-list-item>
                        </template>
                    </v-list>
                    <v-btn
                        large
                        block
                        href="/insure"
                    >
                        ย้อนกลับ
                    </v-btn>
                    
                </v-card>
            </v-container>

            <v-dialog v-model="alert" persistent >
                <v-card>
                    <v-card-title class="text">{{message}}</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeAlert">ปิด</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-app>
    </div>
</template>

<script>

    export default {
        name: 'CarInsureList',
        data: function() {
                return {
                    manageButton: true,
                    valid: true,
                    alert: false,
                    dialogDel: false,
                    dialogCarManage: false,
                    delCar: '',
                    message: '',
                    color: '',
                    items: [],
                    brand: [],
                    carprovinces:[],
                    itemCarEdit: {},
                    inputFile: null,
                    itemsType: [
                        { text: 'รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน (รถเก๋ง)', desc: 'เช่น รถกระบะ 4 ประตู รถเก๋ง', val: '1' },
                        { text: 'รถยนต์บรรทุกส่วนบุคคล (รถกระบะ)', desc: 'เช่น รถตู้', val: '2' },
                        { text: 'รถยนต์นั่งส่วนบุคคลเกิน 7 คน (รถตู้)', desc: 'เช่น รถกระบะ 2 ประตู', val: '3' },
                        { text: 'รถจักรยานยนต์ (มอเตอร์ไซค์)', desc: '', val: '4' },
                    ],
                    years: [],
                    cartype:  { },
                    pickerDC: false,
                    dateDC: null,
                    maxDate: this.$moment(new Date()).format('YYYY-MM-DD'),
                    show:'',
                    norecord: false,
                    loading: true,
                    delID: '',
                    verifyCar: true,
                    rulesBlank: [
                        v => !!v || 'Required',
                    ],
                    rulesPic: [
                        v =>{
                            if(!this.inputFile || this.inputFile.size < 2_097_152) return !!v
                            return 'ขนาดรูปต้องไม่เกิน 2 MB'
                        } 
                    ],
                    role: '',
            };
        },

        mounted () {
            window.scrollTo(0, 0)
        },

        beforeMount() {
            if(this.$router.currentRoute.name == 'CarGasList') this.gas = { text: 'ทั้งหมด', val: '2' };
            this.getRoles();
            this.getCars();
            // this.getBrand();
            // this.getProvince();
        },

        methods: {

            getRoles:async function(){
                var app = this;
                let formData = new FormData();
                formData.append('mode', 'getProfile');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.role = response.data[0].cus18;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            
            getCars:async function(){
                var app = this;
                app.items = [];
                app.norecord = false;
                let formData = new FormData();
                formData.append('gas', 9);
                formData.append('mode', 'getCars');
                // console.log([...formData]);
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.loading = false
                        app.items = response.data
                        if(!app.items.length) app.norecord = true
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            goto: function(param){
                if(param=='/cardetail/add' && this.items.length >= 5){
                    this.alert = true;
                    this.message = 'คุณสามารถเพิ่มรถได้สูงสุด 5 คันเท่านั้น'
                    return;
                }
                this.$router.push(param)
            },

            getColor: function(index){
                if(index%2 == 0){
                    return { 'background-color':''};
                } else {
                    return { 'background-color':'#E7E7E7'};
                }
            },

            closeAlert(){
                this.alert = false;
                this.getCars();
            },
            
            backPage: function(){
                history.back();
            },

        },
    }

</script>
<template>
  <div class="application">
    <AppList />
  </div>
</template>

<script>
// @ is an alias to /src
import AppList from '@/components/ApplicationList.vue'

export default {
  name: 'Application',
  components: {
    AppList
  }
}
</script>
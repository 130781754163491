<template>
  <div class="tax">
    <PlaceList />
  </div>
</template>

<script>
// @ is an alias to /src
import PlaceList from '@/components/PlaceList.vue'

export default {
  name: 'Place',
  components: {
    PlaceList
  }
}
</script>

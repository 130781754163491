<template>
    <div id="app">
        <v-app id="inspire" :style="{background: '#FFC324'}">

            <v-container>
                <v-card flat elevation="5">
                    <v-toolbar
                        style="background-color:#273b81"
                        dark
                    >
                        <v-toolbar-title><v-icon>mdi-cellphone-information</v-icon>&nbsp;App แนะนำ</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <template v-for="(item,index) in applications">
                            <v-row :key="index" dense>
                                <v-col cols="2" class="pt-3">
                                    <v-card elevation="4"><v-img :src="item.picture" /></v-card>
                                </v-col>
                                <v-col cols="10">
                                    <font color="#273b81"><b>{{item.name}}</b></font><br>
                                    {{item.description}}
                                    <p class="pt-2" >
                                    <a :href="item.link_iOS" target="_blank" 
                                        v-if="(userAgent!='Android') && item.link_iOS != null"
                                    >
                                        <img src="images/appstore.png" height="30" class="pr-2"></a>
                                    <span v-else-if="(userAgent!='Android') && item.link_iOS == null" class="pr-2">เฉพาะ Android เท่านั้น</span>
                                    <a :href="item.link_android" target="_blank"
                                        v-if="(userAgent!='iPhone' && userAgent!='iPad') && item.link_android != null"
                                    >
                                        <img src="images/playstore.png" height="30"></a>
                                    <span v-else-if="(userAgent!='iPhone' && userAgent!='iPad') && item.link_android == null">เฉพาะ iPhone และ iPad เท่านั้น</span>
                                    </p>
                                </v-col>
                            </v-row>
                        </template>
                    </v-card-text>
                </v-card>

                <v-btn
                    large
                    @click="backPage()"
                    block
                >
                    ย้อนกลับ
                </v-btn>
                        
            </v-container>

        </v-app>
    </div>
</template>

<script>
    export default {
        name: 'Emergency',
        data: function() {
        return {
                applications: [],
                userAgent: '',
            };
        },

        mounted () {
            window.scrollTo(0, 0)
        },

        beforeMount() {
            this.initialize();
            this.getUA();
        },

        methods: {

            getUA(){
                var app = this;
                let device = "Unknown";
                const ua = {
                    "Generic Linux": /Linux/i,
                    "Android": /Android/i,
                    "BlackBerry": /BlackBerry/i,
                    "Bluebird": /EF500/i,
                    "Chrome OS": /CrOS/i,
                    "Datalogic": /DL-AXIS/i,
                    "Honeywell": /CT50/i,
                    "iPad": /iPad/i,
                    "iPhone": /iPhone/i,
                    "iPod": /iPod/i,
                    "macOS": /Macintosh/i,
                    "Windows": /IEMobile|Windows/i,
                    "Zebra": /TC70|TC55/i,
                }
                Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
                app.userAgent = device;
            },

            initialize:async function(){
                var app = this;
                let formData = new FormData();
                formData.append('mode', 'getApplication');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        console.log("error")
                    }else{
                        app.applications = response.data
                    }
                })
                .catch(function (error) {
                    console.log(error)
                });
            },

            backPage: function(){
                history.back();
            },
        },
    }

</script>
<template>
    <div id="app">
        <v-app id="inspire" :style="{background: '#FFC324'}">
            
            <v-container>
                <v-card>
                    <v-toolbar
                        style="background-color:#273b81"
                        dark
                    >
                        <v-toolbar-title><v-icon>mdi-calculator</v-icon>&nbsp;คำนวณภาษีรถ</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form v-model="verify" ref="form" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="12" md="12" v-show="mycar">
                                    <v-select
                                        v-model="car"
                                        :items="cars"
                                        :item-text="cars => cars.v3+' '+cars.v4+' '+cars.v5+' '+cars.v6"
                                        :item-value="cars => cars"
                                        v-on:change="setTax"
                                        style="font-size: 1em"
                                    ><template v-slot:label><font :color="color1">รถของฉัน</font></template></v-select>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-select
                                        v-model="cartype"
                                        :items="items"
                                        hint=""
                                        item-text="text"
                                        item-value="val"
                                        persistent-hint
                                        return-object
                                        v-on:change="showEngine"
                                        required
                                        style="font-size: 1em"
                                    ><template v-slot:label><font :color="color1">ประเภทรถยนต์</font></template></v-select>
                                </v-col>
                                <v-col cols="12" md="12" v-show="this.cartype.val == '1' || this.cartype.val == '4'">
                                    <v-text-field label="ขนาดเครื่องยนต์ (cc)" v-model="cc" type="number" :rules="rules1">
                                        <template v-slot:label><font :color="color1">ขนาดเครื่องยนต์ (cc)</font></template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" v-show="this.cartype.val == '2' || this.cartype.val == '3'">
                                    <v-text-field label="น้ำหนักเครื่องยนต์ (kg)" v-model="wc" type="number" :rules="rules2">
                                        <template v-slot:label><font :color="color1">น้ำหนักเครื่องยนต์ (kg)</font></template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-menu
                                        v-model="pickerDC"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="dc" label="วันที่จดทะเบียน" prepend-icon="mdi-calendar"
                                                readonly v-bind="attrs" v-on="on" :rules="rules">
                                                <template v-slot:label><font :color="color1">วันที่จดทะเบียน</font></template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker 
                                            v-model="dateDC" 
                                            @input="closeDC"
                                            year-icon="mdi-calendar-blank"
                                            locale="th"
                                            :max="maxDate"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-select
                                        v-model="yc"
                                        :items="years"
                                        label="วันสิ้นอายุภาษีปีล่าสุด"
                                        :rules="rules"
                                    >
                                    <template v-slot:label><font :color="color1">วันสิ้นอายุภาษีปีล่าสุด (ใช้คำนวณภาษี)</font></template>
                                    <template slot="no-data"><font class="pa-5">กรุณาเลือกวันที่จดทะเบียน</font></template>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-menu
                                        v-model="pickerLC"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="lc" label="คำนวณภาษีถึงวันที่" prepend-icon="mdi-calendar"
                                            readonly v-bind="attrs" v-on="on" :rules="rules">
                                            <template v-slot:label><font :color="color1">คำนวณภาษีถึงวันที่</font></template>
                                        </v-text-field>
                                        </template>
                                        <v-date-picker 
                                            v-model="dateLC" 
                                            @input="closeLC"
                                            year-icon="mdi-calendar-blank"
                                            locale="th"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="12" v-show="showNGV">
                                    <v-switch
                                        v-model="ngv"
                                        :color="color1"
                                    ><template v-slot:label><font :color="color1">ติดแก๊ส NGV</font></template></v-switch>
                                </v-col>
                                <v-col cols="12" md="12" v-show="showLegal">
                                    <v-switch
                                        v-model="legal"
                                        class="mt-n1"
                                        :color="color1"
                                    ><template v-slot:label><font :color="color1">จดทะเบียนนิติบุคคล</font></template></v-switch>
                                </v-col>
                            </v-row>
                            <v-divider :color="color2"></v-divider>
                            <div class="py-3" style="font-size: 16px"><font :color="color1"><u>บริการอื่นๆ</u></font></div>
                            <v-switch
                                v-model="op1"
                                :color="color2"
                                label="ตรวจ ตรอ."
                                v-show="showTRO"
                                class="mt-n1"
                            ><template v-slot:label><font :color="color1">ตรวจ ตรอ.</font></template></v-switch>
                            <v-switch
                                v-model="op2"
                                :color="color2"
                                label="พรบ."
                                class="mt-n1"
                            ><template v-slot:label><font :color="color1">พรบ.</font></template></v-switch>
                            <v-switch
                                v-model="op3"
                                :color="color2"
                                label="ฝากต่อภาษี"
                                class="mt-n1"
                            ><template v-slot:label><font :color="color1">ฝากต่อภาษี</font></template></v-switch>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="#273b81"
                            class="white--text"
                            block
                            @click="calculate"
                        >
                            คำนวณเลย
                        </v-btn>
                    </v-card-actions>
                </v-card>

                <v-card
                    class="mt-3"
                    elevation="5"
                    v-show="result"
                >
                    <div class="text-h6 px-5 pt-3" v-if="taxYear != ''">
                        <u>{{taxYear}}</u>
                    </div>
                    <div v-if="haveTax">
                    <template v-for="(item,index) in price">
                    <div class="pt-3" :key="index">
                        <div class="text-h6 px-5">
                            ค่าภาษีปี {{item.split("#")[0]}}&nbsp;<font color="#273b81"> {{currency(item.split("#")[1])}} </font> &nbsp;บาท
                        </div>
                        <div class="text-h6 px-5">
                            ค่าปรับภาษีปี {{item.split("#")[0]}}&nbsp;<font color="#ef2472"> {{currency(item.split("#")[2])}} </font> &nbsp;บาท
                        </div>
                    </div>
                    </template>
                    <!-- <div class="text-h6 px-5 pt-3">
                        รวมค่าภาษี &nbsp;<font> {{tax}} </font> &nbsp;{{unit}}
                    </div>
                    <div class="text-h6 px-5">
                        รวมค่าปรับ &nbsp;<font color="red"> {{fine}} </font> &nbsp;บาท
                    </div> -->

                    <div class="text-h6 px-5 pt-3" v-if="tro != '0' || prb != '0' || service != '0'">
                        <u>ค่าใช้จ่ายอื่นๆ</u>
                    </div>
                    <div class="text-h6 px-5" v-if="tro != '0'">
                        ค่าตรวจ ตรอ. &nbsp;<font color="#273b81"> {{currency(tro)}} </font> &nbsp;บาท
                    </div>
                    <div class="text-h6 px-5" v-if="prb != '0'">
                        ค่า พรบ. &nbsp;<font color="#273b81"> {{currency(prb)}} </font> &nbsp;บาท
                    </div>
                    <div class="text-h6 px-5" v-if="service != '0'">
                        ค่าฝากต่อภาษี &nbsp;<font color="#273b81"> {{currency(service)}} </font> &nbsp;บาท
                    </div>
                    <div class="text-h6 px-5 pt-3">
                        รวม &nbsp;<font color="#273b81"> {{currency(total)}} </font> &nbsp;บาท
                    </div>
                    <div class="subtitle-1 px-5 pb-3" v-if="over == 'y'">
                        <font color="#ef2472">รถของท่านขาดต่อภาษีเกิน 3 ปี<br>จะต้องนำรถเข้ารับการตรวจที่ขนส่งฯ<br>ปรึกษาเจ้าหน้าที่ โทร <a href="tel:027599400">027599400</a></font>
                    </div>
                    <div class="subtitle-1 px-5 pb-3">
                        <font >*** ผลการคำนวณเป็นค่าอ้างอิงค่าใช้จ่ายที่กรมการขนส่งเรียกเก็บเท่านั้น</font><br>
                        <font>*** หากต้องการปรึกษาเจ้าหน้าที่ โทร <a href="tel:027599400">027599400</a></font>
                    </div>
                    </div>
                    <div v-else>
                        <div class="text-h6 px-5 py-3">
                            รวม &nbsp;<font color="#273b81"> 0 </font> &nbsp;บาท
                        </div>
                    </div>

                    
                </v-card>

                <v-btn
                    large
                    @click="backPage()"
                    block
                >
                    ย้อนกลับ
                </v-btn>

                <!-- <v-card
                    class="mt-3"
                    elevation="5"
                    v-show="result"
                >
                    <div class="text-h6 px-5 pt-3" v-if="taxYear != ''">
                        Option
                    </div>
                    <div class="text-h6 px-5">
                        ค่า พรบ. &nbsp;<font> {{prb}} </font> &nbsp;บาท
                    </div>
                    <div class="text-h6 px-5">
                        ค่าบริการฝากต่อภาษี &nbsp;<font> 100 </font> &nbsp;บาท
                    </div>
                    <div class="text-h6 px-5">
                        รวมทั้งสิ้น &nbsp;<font color="#273b81"> {{currency(grandTotal)}} </font> &nbsp;บาท
                    </div>
                </v-card> -->
            </v-container>

            <v-snackbar v-model="alert" top right :color="color">
                {{ message }}
                <v-btn text small fab @click="alert = false"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
            </v-snackbar>

        </v-app>
    </div>
</template>

<script>

    export default {
        name: 'TaxCalculate',
        data: function() {
            return {
                alert: false,
                message: '',
                color: '',
                verify: true,
                rules: [
                        v =>{
                            return !!v || 'Required'
                        } 
                    ],
                rules1: [
                        v =>{
                            if(this.cartype.val == '2' || this.cartype.val == '3') return true
                            return !!v || 'Required'
                        } 
                    ],
                rules2: [
                        v =>{
                            if(this.cartype.val == '1' || this.cartype.val == '4') return true
                            return !!v || 'Required'
                        } 
                    ],
                items: [
                    { text: 'รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน (รถเก๋ง)', desc: 'เช่น รถกระบะ 4 ประตู รถเก๋ง', val: '1' },
                    { text: 'รถยนต์บรรทุกส่วนบุคคล (รถกระบะ)', desc: 'เช่น รถตู้', val: '2' },
                    { text: 'รถยนต์นั่งส่วนบุคคลเกิน 7 คน (รถตู้)', desc: 'เช่น รถกระบะ 2 ประตู', val: '3' },
                    { text: 'รถจักรยานยนต์ (มอเตอร์ไซค์)', desc: '', val: '4' },
                ],
                // years: [
                //     { text: '5 ปีแรก', val: '0'},
                //     { text: 'ปีที่ 6', val: '10'},
                //     { text: 'ปีที่ 7', val: '20'},
                //     { text: 'ปีที่ 8', val: '30'},
                //     { text: 'ปีที่ 9', val: '40'},
                //     { text: 'ปีที่ 10', val: '50'},
                // ],
                years: [],
                cartype:  { text: 'รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน (รถเก๋ง)', desc: 'เช่น รถกระบะ 4 ประตู รถเก๋ง', val: '1' },
                cc: '',
                wc: '',
                dc: '',
                yc: '',
                lc: '',
                ngv: false,
                legal: false,
                op1: false,
                op2: false,
                op3: false,
                pickerDC: false,
                dateDC: null,
                pickerLC: false,
                dateLC: null,
                maxDate: this.$moment(new Date()).format('YYYY-MM-DD'),
                show:false,
                showNGV:true,
                showLegal:false,
                showTRO:false,
                result:false,
                tax:'-',
                fine: '-',
                total: '-',
                unit: 'บาท',
                over: '',
                taxYear: '',
                tro: '0',
                prb: '0',
                service: '0',
                grandTotal: '',
                price: [],
                mycar: false,
                car: {},
                cars: [],
                color1: '#273b81',
                color2: '#FFC324',
                color3: '#ef2472',
                haveTax: false,
            };
        },

        mounted () {
            window.scrollTo(0, 0)
        },

        beforeMount() {
            this.showEngine();
            this.getCars();
            // this.years.push('')
        },

        methods: {

            showEngine(){
                this.show = false;
                this.result = false;
                this.showNGV = true;
                this.showLegal = false;
                if(this.cartype.val == '1') {
                    this.showLegal = true;
                }
                if(this.cartype.val == '4') {
                    this.ngv = false;
                    this.showNGV = false;
                }
                this.showOP1();
            },

            getCars:async function(){
                var app = this;
                let formData = new FormData();
                // formData.append('gas', '2');
                formData.append('mode', 'getCars');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.loading = false
                        app.cars = response.data
                        app.cars.push({'v3':'ไม่ระบุ','v4':'','v5':'','v6':''});
                        if(app.cars.length) app.mycar = true
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            closeDC (){
                this.pickerDC = false;
                this.yc = '';
                this.dc = this.dateDC ? this.$moment(this.dateDC).add(543, 'year').format('YYYY-MM-DD') : '';
                this.years = [];
                this.years.push('');
                for(var i=(new Date().getFullYear())+1;i>=this.$moment(this.dateDC).format('YYYY');i--){
                    this.years.push(i+543);
                }
                this.showOP1();
            },

            closeLC (){
                this.pickerLC = false;
                this.lc = this.dateLC ? this.$moment(this.dateLC).add(543, 'year').format('YYYY-MM-DD') : '';
                this.showOP1();
            },

            showOP1 (){
                this.showTRO = false;
                if(this.dc != '' && this.lc != ''){
                    var year = 7;
                    if(this.cartype.val == '4') year = 5;
                    var chkDate = this.$moment(this.dc).add(year, 'year')
                    var lc = this.$moment(this.lc);
                    if(chkDate.diff(lc, "days") <= 90){
                        this.showTRO = true;
                    }
                }
            },

            setTax (){
                this.cartype = '1';
                this.cc = ''; this.wc = '';
                this.dc = '';
                this.yc = '';
                this.ngv = false;
                this.showNGV = true;
                this.legal = false;
                if(this.car.v3 != 'ไม่ระบุ'){
                    if(this.car.v8 != null) { 
                        this.cartype = { val: this.car.v8 }; 
                        if(this.cartype == '4') this.showNGV = false;
                    } else {
                        this.cartype = { text: 'รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน (รถเก๋ง)', desc: 'เช่น รถกระบะ 4 ประตู รถเก๋ง', val: '1' };
                    }
                    if(this.car.v9 != null) { this.cc = this.car.v9; this.wc = this.car.v9;}
                    if(this.car.v10 != null) { this.dc = this.car.v10;}
                    if(this.car.v11 != null) { 
                        for(var i=(new Date().getFullYear())+544;i>this.car.v11;i--){
                            this.years.push(i);
                        }
                        this.years.push(this.car.v11); 
                        this.yc = this.car.v11;
                    }
                    if(this.car.v12 != '0') { this.ngv = true;}
                    if(this.car.v13 != '0') { this.legal = true;}
                    if(this.car.v8 == null || this.car.v9 == null || this.car.v10 == null || this.car.v11 == null){
                        this.color = 'error';
                        this.alert = true;
                        this.message = 'กรุณาเพิ่มข้อมูลในรถของฉัน';
                    }
                } else {
                    this.cartype = { text: 'รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน (รถเก๋ง)', desc: 'เช่น รถกระบะ 4 ประตู รถเก๋ง', val: '1' };
                    this.years = [];
                }
                this.showOP1();
            },
            
            calculate:async function(){
                this.result = false;
                this.tro = '0';
                this.prb = '0';
                this.service = '0';
                if(!this.$refs.form.validate()) return;
                var app = this;
                let formData = new FormData();
                formData.append('cartype', this.cartype.val);
                formData.append('cc', this.cc);
                formData.append('wc', this.wc);
                formData.append('dc', this.dc);
                formData.append('yc', this.yc);
                formData.append('lc', this.lc);
                formData.append('ngv', this.ngv);
                formData.append('legal', this.legal);
                var op1 = false;
                if(this.showTRO) op1 = this.op1;
                formData.append('op1', op1);
                formData.append('op2', this.op2);
                formData.append('op3', this.op3);
                formData.append('mode', 'calculate');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin");
                    }else{
                        app.result = true
                        app.haveTax = true;
                        const output = response.data.split("|");
                        if(output[7] == 0){
                            app.haveTax = false;
                        } else {
                            app.haveTax = true;
                        }
                        app.total = output[0];
                        app.over = output[1];
                        app.taxYear = output[2];
                        app.tro = output[3];
                        app.prb = output[4];
                        app.service = output[5];
                        app.price = output[6].split("@");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            currency(price){
                return new Intl.NumberFormat('en').format(price);
            },

            backPage: function(){
                history.back();
            },

        },
    }

</script>
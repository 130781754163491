<template>
  <div class="emer">
    <Emergency />
  </div>
</template>

<script>
// @ is an alias to /src
import Emergency from '@/components/Emergency.vue'

export default {
  name: 'Emer',
  components: {
    Emergency
  }
}
</script>

<template>
<div id="app">
    <v-app id="inspire" :style="{background: '#FFC324'}">
        
        <v-container>
            <v-row no-gutters>
            <v-col
                v-for="(item, i) in items"
                :key="i"
                cols="12"
                md="12"
                >
                <v-container>
                <v-card
                    elevation="5"
                >
                    
                    <!-- <v-img 
                        :src="'images/'+item.ins9+'.png'"
                        class="white--text align-end"
                        height="200px"
                    >
                        <v-card-title
                        class="text-h6"
                        ><span style="background-color: rgba(0, 0, 0, 0.7);">{{item.ins4}}</span></v-card-title>
                    </v-img> -->
                    <!-- <v-card-title
                        class="text-h6"
                    >{{item.ins4}}</v-card-title> -->
                    <v-toolbar
                        style="background-color:#273b81"
                        dark
                    >
                        <v-toolbar-title>
                            <!-- <v-icon>mdi-file-document-outline</v-icon>&nbsp; -->
                            <span v-if="item.ins9 == '1'">{{item.v5}} {{item.v6}} ({{item.ins7.substring(0,4)}})</span>
                            <span v-else>{{item.ins4}} ({{item.ins7.substring(0,4)}})</span>
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-subtitle>
                        <center>
                            <img v-if="item.ins9 == '1' && !item.v7" :src="'../../images/car.jpg'" width="80%">
                            <img v-if="item.ins9 == '1' && item.v7" :src="'../../images/cars/'+item.v7" width="80%">
                        </center>
                        <v-simple-table>
                            <tbody>
                                <tr v-if="item.ins9 == '1'">
                                    <td width="45%">เลขที่กรมธรรม์</td><td style="">{{item.ins3}}</td>
                                </tr>
                                <tr>
                                    <td>บริษัทประกัน</td><td>{{item.ins10}}</td>
                                </tr>
                                <tr v-if="item.ins9 == '1'">
                                    <td>ประเภทประกัน</td><td>{{item.ins4}}</td>
                                </tr>
                                <tr v-if="item.ins9 == '1'">
                                    <td>การซ่อม</td><td>{{item.ins5}}</td>
                                </tr>
                                <tr>
                                    <td>วันเริ่มต้น</td><td>{{setDate(item.ins6)}}</td>
                                </tr>
                                <tr>
                                    <td>วันสิ้นสุด</td><td>{{setDate(item.ins7)}}</td>
                                </tr>
                                <!-- <tr>
                                    <td>รายละเอียด</td><td>{{item.ins5}}</td>
                                </tr> -->
                            </tbody>
                        </v-simple-table>

                        <v-btn class="mt-4" v-if="item.ins8 != ''" text :href="$hostUrl+'get/preview.php?link='+item.ins8" target="_blank">
                            <v-icon>
                                mdi-text-box-outline
                            </v-icon>
                            <font color="#273b81">ดูไฟล์กรมธรรม์</font>
                        </v-btn>
                    </v-card-subtitle>

                    <!-- <iframe :src="item.ins8" :style="'width:100%;height:'+height+'px'" frameborder="0" id="Iframe"></iframe> -->
                    
                </v-card>

                <v-btn
                    large
                    @click="backPage()"
                    block
                >
                    ย้อนกลับ
                </v-btn>
                </v-container>
            </v-col>
            </v-row>

            
            
        </v-container>

    </v-app>
</div>
</template>

<script>
    export default {
        name: 'InsureList',
        data: function() {
            return {
                valid: false,
                alert: false,
                message: '',
                color: '',
                items: [],
                height: 470,
                back: 'web?insure/<?php echo $id ?>',
            };
        },

        mounted () {
            window.scrollTo(0, 0)
        },

        beforeMount() {
            this.getInsure();
        },

        methods: {
            
            getInsure:async function(){
                var app = this;
                let formData = new FormData();
                formData.append('ins1', this.$route.params.id2);
                formData.append('ins9', this.$route.params.id);
                formData.append('mode', 'getInsure');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.items = response.data
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            setDate(date){
                return date.substring(8,10)+"/"+date.substring(5,7)+"/"+date.substring(0,4);
            },

            backPage: function(){
                history.back();
            },

        },
    }
</script>
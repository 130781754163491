<template>
  <div class="history">
    <div v-if="$route.params.hid == null">
      <HistoryList />
    </div>
    <div v-else>
      <HistoryDetail />
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import HistoryList from '@/components/HistoryList.vue'
import HistoryDetail from '@/components/HistoryDetail.vue'

export default {
  name: 'History',
  components: {
    HistoryList,
    HistoryDetail
  }
}
</script>

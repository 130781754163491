<template>
  <div class="loc">
    <Location />
  </div>
</template>

<script>
// @ is an alias to /src
import Location from '@/components/LocationList.vue'

export default {
  name: 'Loc',
  components: {
    Location
  }
}
</script>

<template>
  <div class="profile">
    <ProfileDetail />
  </div>
</template>

<script>
// @ is an alias to /src
import ProfileDetail from '@/components/ProfileDetail.vue'

export default {
  name: 'Profile',
  components: {
    ProfileDetail
  }
}
</script>

<template>
    <div id="app">
        <v-app id="inspire" :style="{background: '#FFC324'}">
            
            <v-container>
                <v-card>
                    <v-toolbar
                        style="background-color:#273b81"
                        dark
                    >
                        <v-toolbar-title><v-icon>mdi-car-side</v-icon>&nbsp;รายละเอียด</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form v-model="verifyCar" ref="formCar" lazy-validation>
                            <v-row>
                                <v-col v-if="role=='agent'" cols="12" md="6" class="mb-n5">
                                    <v-text-field label="ชื่อ นามสกุล" v-model="itemCarEdit.v14"></v-text-field>
                                </v-col>
                                <v-col v-if="role=='agent'" cols="12" md="6" class="mb-n5">
                                    <v-text-field label="เบอร์โทรศัพท์" v-model="itemCarEdit.v15"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" class="mb-n5">
                                    <v-autocomplete
                                        ref="itemCarEdit.v3"
                                        v-model="itemCarEdit.v3"
                                        :items="brand"
                                        :rules="rulesBlank"
                                        label="ยี่ห้อ"
                                        :menu-props="{ maxHeight: '100%' }"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6" class="mb-n5">
                                    <v-text-field label="รุ่น" :rules="rulesBlank" v-model="itemCarEdit.v4"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" class="mb-n5">
                                    <v-text-field label="ทะเบียนรถ" :rules="rulesBlank" v-model="itemCarEdit.v5"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" class="mb-n5">
                                    <v-autocomplete
                                        ref="itemCarEdit.v6"
                                        v-model="itemCarEdit.v6"
                                        :items="carprovinces"
                                        :rules="rulesBlank"
                                        label="จังหวัด"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6" class="mb-n5">
                                    <v-file-input
                                        accept=".jpg,.jpeg,.png"
                                        label="รูปภาพ"
                                        v-model="inputFile"
                                    ></v-file-input>
                                    <img v-if="itemCarEdit.v7" :src="'../images/cars/'+itemCarEdit.v7" width="100%">
                                </v-col>
                                <v-col cols="12" md="12" class="mb-n5">
                                    <v-select
                                        v-model="cartype"
                                        :items="itemsType"
                                        hint=""
                                        label="ประเภทรถยนต์"
                                        item-text="text"
                                        item-value="val"
                                        persistent-hint
                                        return-object
                                        v-on:change="showEngine"
                                        required
                                        style="font-size: 1em"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="12" class="mb-n5" v-show="this.show == '1' || this.show == '4'">
                                    <v-text-field label="ขนาดเครื่องยนต์ (cc)" v-model="itemCarEdit.v9" type="number"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" class="mb-n5" v-show="this.show == '2' || this.show == '3'">
                                    <v-text-field label="น้ำหนักเครื่องยนต์ (kg)" v-model="itemCarEdit.v9" type="number"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" class="mb-n5">
                                    <v-menu
                                        v-model="pickerDC"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="itemCarEdit.v10" label="วันที่จดทะเบียน" prepend-icon="mdi-calendar"
                                            readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker 
                                            v-model="dateDC" 
                                            @input="closeDC"
                                            year-icon="mdi-calendar-blank"
                                            locale="th"
                                            :max="maxDate"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="12" class="mb-n5">
                                    <v-select
                                        v-model="itemCarEdit.v11"
                                        :items="years"
                                        label="วันสิ้นอายุภาษีปีล่าสุด (ใช้คำนวณภาษี)"
                                    >
                                    <template slot="no-data"><font class="pa-5">กรุณาเลือกวันที่จดทะเบียน</font></template>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="12" class="mb-n5" v-show="this.show != '4'">
                                    <v-switch
                                        v-model="itemCarEdit.v12"
                                        @click="itemCarEdit.v12?itemCarEdit.lpg=false:''"
                                        label="ติดแก๊ส NGV"
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12" md="12" class="mb-n5" v-show="this.show != '4'">
                                    <v-switch
                                        v-model="itemCarEdit.lpg"
                                        @click="itemCarEdit.lpg?itemCarEdit.v12=false:''"
                                        label="ติดแก๊ส LPG"
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12" md="12" class="mb-n5" v-show="itemCarEdit.v12 || itemCarEdit.lpg">
                                    <v-text-field label="รายละเอียดแก๊ส" v-model="itemCarEdit.gasDetail"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" v-show="this.show == '1'">
                                    <v-switch
                                        v-model="itemCarEdit.v13"
                                        label="จดทะเบียนนิติบุคคล"
                                    ></v-switch>
                                </v-col>
                            </v-row>
                        </v-form>

                        <v-btn
                            v-if="role!='agent'"
                            :color="$blue"
                            class="white--text mt-3"
                            @click="manageCar()"
                            block
                        >
                            {{$route.params.vid!='add'?'แก้ไข':'เพิ่มรถ' }}
                        </v-btn>
                        <v-btn
                            v-if="role!='agent'"
                            v-show="$route.params.vid!='add'"
                            :color="$red"
                            class="white--text my-3"
                            @click="deleteCarDialog()"
                            block
                        >
                            ลบรถ
                        </v-btn>
                    </v-card-text>
                        

                    <v-btn
                        large
                        @click="backPage()"
                        block
                    >
                        ย้อนกลับ
                    </v-btn>
                </v-card>
            </v-container>

            <v-dialog v-model="dialogDel" persistent max-width="500px">
                <v-card>
                    <v-card-title class="text">คุณต้องการลบ {{delCar}}</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :color="this.$red" class="white--text" @click="deleteCar">ลบ</v-btn>
                        <v-btn :color="this.$gray" class="white--text" @click="dialogDel = false">ไม่ลบ</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="alert" persistent max-width="500px">
                <v-card>
                    <v-card-title class="text">{{message}}</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="backPage()">ปิด</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-app>
    </div>
</template>

<script>
    export default {
        name: 'CarDetail',
        data: function() {
            return {
                valid: true,
                alert: false,
                dialogDel: false,
                dialogCarManage: false,
                delCar: '',
                message: '',
                color: '',
                items: [],
                brand: [],
                carprovinces:[],
                itemCarEdit: {},
                inputFile: null,
                itemsType: [
                    { text: 'รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน (รถเก๋ง)', desc: 'เช่น รถกระบะ 4 ประตู รถเก๋ง', val: '1' },
                    { text: 'รถยนต์บรรทุกส่วนบุคคล (รถกระบะ)', desc: 'เช่น รถตู้', val: '2' },
                    { text: 'รถยนต์นั่งส่วนบุคคลเกิน 7 คน (รถตู้)', desc: 'เช่น รถกระบะ 2 ประตู', val: '3' },
                    { text: 'รถจักรยานยนต์ (มอเตอร์ไซค์)', desc: '', val: '4' },
                ],
                years: [],
                cartype:  { },
                pickerDC: false,
                dateDC: null,
                maxDate: this.$moment(new Date()).format('YYYY-MM-DD'),
                show:'',
                norecord: false,
                loading: true,
                delID: '',
                verifyCar: true,
                rulesBlank: [
                    v => !!v || 'Required',
                ],
                rulesPic: [
                    v =>{
                        if(!this.inputFile || this.inputFile.size < 2_097_152) return !!v
                        return 'ขนาดรูปต้องไม่เกิน 2 MB'
                    } 
                ],
                role: '',
            };
        },

        mounted () {
            window.scrollTo(0, 0)
        },

        beforeMount() {
            this.getRoles();
            this.getCar();
            this.getBrand();
            this.getProvince();
        },

        methods: {

            getRoles:async function(){
                var app = this;
                let formData = new FormData();
                formData.append('mode', 'getProfile');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.role = response.data[0].cus18;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            getCar:async function(){
                if(this.$route.params.vid == 'add') return;
                var app = this;
                let formData = new FormData();
                formData.append('mode', 'getCars');
                formData.append('vid', this.$route.params.vid);
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.itemCarEdit = response.data[0];
                        if(app.itemCarEdit.v8 != null) {
                            app.cartype = app.itemCarEdit.v8
                            app.show = app.itemCarEdit.v8
                        }
                        app.itemCarEdit.v12 = (app.itemCarEdit.v12==1)?true:false;
                        app.itemCarEdit.v13 = (app.itemCarEdit.v13==1)?true:false;
                        app.itemCarEdit.lpg = (app.itemCarEdit.lpg==1)?true:false;
                        app.dateDC = app.itemCarEdit.v10 ? app.$moment(app.itemCarEdit.v10).add(-543, 'year').format('YYYY-MM-DD') : '';
                        if(app.itemCarEdit.v11) app.years.push(app.itemCarEdit.v11)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            getBrand:async function(){
                var app = this;
                let formData = new FormData();
                formData.append('mode', 'getBrand');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.brand = response.data
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            getProvince: async function () {
                var app = this;
                var formData = new FormData();
                formData.append("mode", 'getProvince')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    app.carprovinces = response.data
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            showEngine(){
                this.show = this.cartype.val;
            },

            closeDC (){
                this.pickerDC = false;
                this.itemCarEdit.v11 = '';
                this.itemCarEdit.v10 = this.dateDC ? this.$moment(this.dateDC).add(543, 'year').format('YYYY-MM-DD') : '';
                this.years = [];
                this.years.push('');
                for(var i=(new Date().getFullYear());i>=(this.dateDC ? this.$moment(this.dateDC).format('YYYY') : '');i--){
                    this.years.push(i+543);
                }
            },

            manageCar: async function () {
                var app = this;
                if(!this.$refs.formCar.validate()) return;
                var formData = new FormData();
                if(app.itemCarEdit.v1) formData.append("v1", app.itemCarEdit.v1)
                if(app.itemCarEdit.v3) formData.append("v3", app.itemCarEdit.v3)
                if(app.itemCarEdit.v4) formData.append("v4", app.itemCarEdit.v4)
                if(app.itemCarEdit.v5) formData.append("v5", app.itemCarEdit.v5)
                if(app.itemCarEdit.v6) formData.append("v6", app.itemCarEdit.v6)
                if(app.itemCarEdit.v7) formData.append("v7", app.itemCarEdit.v7)
                if(app.cartype.val) formData.append("v8", app.cartype.val)
                if(app.itemCarEdit.v9) formData.append("v9", app.itemCarEdit.v9)
                if(app.itemCarEdit.v10) formData.append("v10", app.itemCarEdit.v10)
                if(app.itemCarEdit.v11) formData.append("v11", app.itemCarEdit.v11)
                var ngv = '0';
                if(app.itemCarEdit.v12 && app.show != '4') ngv = '1';
                formData.append("v12", ngv)
                var lpg = '0';
                if(app.itemCarEdit.lpg && app.show != '4') lpg = '1';
                formData.append("lpg", lpg);
                var gasDetail = '';
                if(lpg!=0 || ngv!=0 ) gasDetail = app.itemCarEdit.gasDetail?app.itemCarEdit.gasDetail:'';
                formData.append("gasDetail", gasDetail);
                var legal = '0';
                if(app.itemCarEdit.v13 && app.show == '1') legal = '1';
                formData.append("v13", legal)
                if(app.itemCarEdit.v14) formData.append("v14", app.itemCarEdit.v14)
                if(app.itemCarEdit.v15) formData.append("v15", app.itemCarEdit.v15)
                if(app.inputFile) formData.append("inputFile", app.inputFile)
                formData.append("mode", 'manageCar');
                // console.log([...formData])
                await this.$axios.post(this.$hostUrl+'get/', formData,
                {
                    headers: {'Content-Type': 'multipart/form-data'}
                })
                .then(function (response) {
                    if(!response.data){
                        app.message = response
                        app.alert = true
                    }else{
                        app.dialogCarManage = false
                        app.alert = true;
                        app.message = response.data;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    app.dialogCarManage = false
                });
            },

            deleteCarDialog: function(){
                let obj = this.itemCarEdit;
                this.delCar = obj.v3+" "+obj.v4;
                this.delID = obj.v1;
                this.dialogDel = true;
            },

            deleteCar:async function(){
                this.dialogDel = false;
                var app = this;
                let formData = new FormData();
                if(app.delID) formData.append('v1', app.delID);
                formData.append('mode', 'deleteCar');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.alert = true;
                        app.message = response.data;
                        app.delID = '';
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            backPage: function(){
                history.back();
            },

        },
    }
</script>
<template>
  <div class="tax">
    <TaxCalculate />
  </div>
</template>

<script>
// @ is an alias to /src
import TaxCalculate from '@/components/TaxCalculate.vue'

export default {
  name: 'Profile',
  components: {
    TaxCalculate
  }
}
</script>

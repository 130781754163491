<template>
    <div id="app">
        <v-app id="inspire" :style="{background: '#FFC324'}">
            
            <v-container>
                
                <v-card
                    class="mx-auto"
                    max-width="500"
                >

                    <v-toolbar
                        style="background-color:#273b81"
                        dark
                    >
                        <v-toolbar-title>
                            <v-icon>mdi-wrench</v-icon>&nbsp;ประวัติการใช้บริการ
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn v-if="role!='agent'" icon @click="goto('/history/new')">
                            <v-icon>mdi-note-plus-outline</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-progress-circular
                        v-show="loading"
                        indeterminate
                        color="amber"
                    ></v-progress-circular>

                    <v-card
                        elevation="5"
                        v-show="norecord"
                    >
                        <v-card-title
                        class="text-h6"
                        >ไม่พบข้อมูล</v-card-title>
                    </v-card>

                    <!-- <v-list two-line v-show="!norecord">
                        <v-list-item-group>
                            <template v-for="(item,index) in items">
                                <v-list-item :key="index" :style="getColor(index)">
                                    <v-avatar @click="goto('/history/'+item.hid)" tile>
                                        <v-icon>mdi-wrench</v-icon>
                                    </v-avatar>
                                    &nbsp;
                                    <v-list-item-content @click="goto('/history/'+item.hid)">
                                        <v-list-item-title>
                                            <span><font color="#273b81">{{item.name}}</font><br></span>
                                            <span>{{item.store}} {{item.startdate}}<br></span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list> -->

                    <v-card flat elevation="5" v-show="!norecord">
                        <v-card-text>

                            <v-expansion-panels class="pt-3" focusable>
                                <v-expansion-panel
                                    v-for="(car,i) in Object.keys(items)"
                                    :key="i"
                                >
                                <v-expansion-panel-header>
                                    <div><font color="#273b81">{{car.split("|")[0]}}</font><br><br>
                                    <span class="pt-2">{{car.split("|")[1]}}</span></div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="ml-n2 mr-n6 mb-n2">
                                    <!-- <template v-for="(item,index) in items[car]">
                                        <div :key="index">
                                            <div class="px-2 pt-2">
                                                <font><b>{{item.name}}</b></font>
                                            </div>
                                            <div class="px-2" v-if="item.detail != null">
                                                {{item.detail}}
                                            </div>
                                            <div>
                                                <v-btn v-if="item.tel != null" 
                                                    :href="'tel:'+item.tel" 
                                                    color="#FFC324"
                                                    text
                                                >
                                                    <v-icon>mdi-phone</v-icon> <font color="black">{{item.tel}}</font>
                                                </v-btn>
                                                <v-btn  v-if="item.latlon != null" 
                                                    :href="'http://maps.google.com/maps?q=loc:'+item.latlon" 
                                                    target="_blank" 
                                                    color="#ef2472"
                                                    text
                                                >
                                                    <v-icon>mdi-map-marker</v-icon> <font color="black">แผนที่</font>
                                                </v-btn>
                                            </div>
                                        </div>
                                    </template> -->
                                    <v-expansion-panels accordion class="pt-2">
                                        <v-expansion-panel
                                            v-for="(item,index) in items[car]"
                                            :key="index"
                                        >
                                        <v-expansion-panel-header>
                                            <b><i>{{item.name}} ({{item.sdate}})</i></b>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <div class="px-2 pt-2" v-if="item.store != null">
                                                {{item.store}}
                                            </div>
                                            <div class="px-2 pt-2" v-if="item.detail != null">
                                                <pre>{{item.detail}}</pre>
                                            </div>
                                            <div class="px-2 pt-2" v-if="item.alert != null">
                                                แจ้งเตือนวันที่ {{item.alert}}
                                            </div>
                                            <div class="pt-2">
                                                <v-btn v-if="item.tel != null" 
                                                    :href="'tel:'+item.tel" 
                                                    color="#FFC324"
                                                    text
                                                >
                                                    <v-icon>mdi-phone</v-icon> <font color="black">{{item.tel}}</font>
                                                </v-btn>
                                                <v-btn  v-if="item.latlon != null" 
                                                    :href="'http://maps.google.com/maps?q=loc:'+item.latlon" 
                                                    target="_blank" 
                                                    color="#ef2472"
                                                    text
                                                >
                                                    <v-icon>mdi-map-marker</v-icon> <font color="black">แผนที่</font>
                                                </v-btn>
                                            </div>
                                            <div v-if="role!='agent'" class="pt-2">
                                                <v-btn
                                                    @click="goto('/history/'+item.hid)"
                                                    text
                                                >
                                                    <v-icon>mdi-square-edit-outline</v-icon> <font color="black">แก้ไข</font>
                                                </v-btn>
                                            </div>
                                        </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card-text>
                    </v-card>

                    <v-btn
                        large
                        @click="backPage()"
                        block
                    >
                        ย้อนกลับ
                    </v-btn>
                    
                </v-card>
            </v-container>
            
        </v-app>
    </div>
</template>

<script>

    export default {
        name: 'CarList',
        data: function() {
                return {
                    items: [],
                    norecord: false,
                    loading: true,
                    role: '',
            };
        },

        mounted () {
            window.scrollTo(0, 0)
        },

        beforeMount() {
            this.getRoles();
            this.getHistory();
        },

        methods: {

            getRoles:async function(){
                var app = this;
                let formData = new FormData();
                formData.append('mode', 'getProfile');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.role = response.data[0].cus18;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            
            getHistory:async function(){
                var app = this;
                app.loading = true
                let formData = new FormData();
                formData.append('mode', 'getHistory');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.loading = false
                        app.items = response.data
                        if(app.items.length == 0) app.norecord = true
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            getColor: function(index){
                if(index%2 == 0){
                    return { 'background-color':''};
                } else {
                    return { 'background-color':'#E7E7E7'};
                }
            },

            goto: function(param){
                this.$router.push(param)
            },

            backPage: function(){
                history.back();
            },
        },
    }

</script>